import React from 'react';
import TeamTable from './TeamTable';
import DamageChart from './DamageChart';

const MatchDetailsSkeleton = ({ isDarkMode }) => (
  <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} animate-pulse`}>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {[...Array(2)].map((_, index) => (
        <div key={index} className={`rounded-xl shadow border-2 ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-gray-100 border-gray-200'}`}>
          <div className="h-64"></div>
        </div>
      ))}
    </div>
    <div className={`mt-6 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-lg shadow p-4`}>
      <div className="h-80"></div>
    </div>
  </div>
);

const MatchDetails = ({ match, handlePlayerClick, gameVersion, isDarkMode }) => {
  if (!match) {
    return <MatchDetailsSkeleton isDarkMode={isDarkMode} />;
  }

  const blueTeam = match.participants.filter(p => p.teamId === 100);
  const redTeam = match.participants.filter(p => p.teamId === 200);

  return (
    <div className={`p-4 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} animate-fadeIn`}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <TeamTable 
          teamParticipants={blueTeam} 
          isWinner={match.winningTeamId === 100}
          handlePlayerClick={handlePlayerClick}
          gameVersion={gameVersion}
          teamColor="blue"
          isDarkMode={isDarkMode}
        />
        <TeamTable 
          teamParticipants={redTeam} 
          isWinner={match.winningTeamId === 200}
          handlePlayerClick={handlePlayerClick}
          gameVersion={gameVersion}
          teamColor="red"
          isDarkMode={isDarkMode}
        />
      </div>
      <DamageChart 
        matchDetails={match}
        gameVersion={gameVersion}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default MatchDetails;