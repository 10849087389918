import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';

const MatchFoundModal = ({ acceptedPlayers, onAccept, isDarkMode }) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [localCountdown, setLocalCountdown] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalCountdown((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - (100 / 150);
      });
    }, 100);

    return () => clearInterval(timer);
  }, []);

  const handleAccept = () => {
    setIsAccepted(true);
    onAccept();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-8 rounded-lg text-center w-full max-w-md mx-4 min-h-[250px] flex flex-col`}>
        <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>Match Found!</h2>
        <div className="mb-6">
          <div className="w-full flex justify-between">
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className={`w-[8%] h-3 rounded-full ${
                  index < acceptedPlayers ? 'bg-green-500' : (isDarkMode ? 'bg-gray-600' : 'bg-gray-300')
                }`}
              ></div>
            ))}
          </div>
        </div>
        <div className="mb-6">
          <div className={`w-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-3 overflow-hidden`}>
            <div 
              className="bg-blue-600 h-3 rounded-full transition-all duration-100 ease-linear"
              style={{ width: `${Math.max(localCountdown, 0)}%` }}
            ></div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-auto">
          {isAccepted ? (
            <div className={`inline-flex items-center justify-center ${
              isDarkMode ? 'bg-green-800 text-green-200' : 'bg-green-100 text-green-800'
            } px-4 py-2 rounded-md`}>
              <CheckCircle className="mr-2" size={24} />
              <span className="font-semibold">Match Accepted</span>
            </div>
          ) : (
            <button
              onClick={handleAccept}
              className={`${
                isDarkMode
                  ? 'bg-green-600 hover:bg-green-700 text-white'
                  : 'bg-green-500 hover:bg-green-600 text-white'
              } font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out text-lg`}
            >
              Accept
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchFoundModal;