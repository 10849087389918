import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { X, Plus, Upload } from 'lucide-react';
import logoImage from '../assets/unl.png';
const config = require('../utils/config');

const UNLAdminTournamentCodeGenerator = ({ isDarkMode, isUNLMode }) => {
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState('');
  const [newTeamFullName, setNewTeamFullName] = useState('');
  const [newTeamColor, setNewTeamColor] = useState('#000000');
  const [newTeamLogo, setNewTeamLogo] = useState(null);
  const [newTeamLogoPreview, setNewTeamLogoPreview] = useState(null);
  const [blueTeam, setBlueTeam] = useState(null);
  const [redTeam, setRedTeam] = useState(null);
  const [tournamentCode, setTournamentCode] = useState('');
  const [draggedTeam, setDraggedTeam] = useState(null);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${config.UNL_API_URL}/api/unl/teams`);
      setTeams(response.data);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const addTeam = async () => {
    if (newTeam.trim() === '' || newTeamFullName.trim() === '') return;
    const formData = new FormData();
    formData.append('name', newTeam);
    formData.append('fullName', newTeamFullName);
    formData.append('color', newTeamColor);
    if (newTeamLogo) {
      formData.append('logo', newTeamLogo);
    }

    try {
      await axios.post(`${config.UNL_API_URL}/api/unl/teams`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setNewTeam('');
      setNewTeamFullName('');
      setNewTeamColor('#000000');
      setNewTeamLogo(null);
      setNewTeamLogoPreview(null);
      setShowAddTeam(false);
      fetchTeams();
    } catch (error) {
      console.error('Error adding team:', error);
    }
  };

  const deleteTeam = async (id) => {
    try {
      await axios.delete(`${config.UNL_API_URL}/api/unl/teams/${id}`);
      fetchTeams();
      if (blueTeam && blueTeam.id === id) setBlueTeam(null);
      if (redTeam && redTeam.id === id) setRedTeam(null);
      setTeamToDelete(null);
    } catch (error) {
      console.error('Error deleting team:', error);
    }
  };

  const generateCode = async () => {
    if (!blueTeam || !redTeam) return;
    try {
      const response = await axios.post(`${config.UNL_API_URL}/api/unl/generate-code`, { blueTeam: blueTeam.name, redTeam: redTeam.name });
      setTournamentCode(response.data.tournamentCode);
    } catch (error) {
      console.error('Error generating tournament code:', error);
    }
  };

  const getBackgroundColor = () => {
    if (isUNLMode) return 'bg-[#7100e4]';
    return isDarkMode ? 'bg-gray-800' : 'bg-white';
  };

  const getTextColor = () => {
    if (isUNLMode) return 'text-white';
    return isDarkMode ? 'text-white' : 'text-black';
  };

  const handleTeamClick = (team) => {
    if (!blueTeam) {
      setBlueTeam(team);
    } else if (!redTeam) {
      setRedTeam(team);
    }
  };

  const handleDragStart = (e, team) => {
    setDraggedTeam(team);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (side) => {
    if (draggedTeam) {
      if (side === 'blue') {
        setBlueTeam(draggedTeam);
      } else {
        setRedTeam(draggedTeam);
      }
      setDraggedTeam(null);
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setNewTeamLogo(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewTeamLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setNewTeamLogoPreview(null);
    }
  };

  const TeamLogo = ({ team, size = 'w-12 h-12' }) => {
    if (team.logo) {
      return <img src={`${config.UNL_API_URL}${team.logo}`} alt={`${team.name} logo`} className={`${size} mr-3 rounded object-cover`} />;
    } else {
      return <img src={logoImage} alt={`${team.name} placeholder`} className={`${size} mr-3 rounded bg-white`} />;
    }
  };

  const ConfirmationModal = ({ team, onConfirm, onCancel }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} p-6 rounded-lg max-w-sm w-full`}>
        <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
        <p className="mb-4">
          Are you sure you want to delete the team {team.name} ({team.fullName})?
          This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`max-w-6xl mx-auto mt-10 p-6 rounded-lg shadow-xl ${getBackgroundColor()} ${getTextColor()}`}>
      <h1 className="text-3xl font-bold mb-6 text-center">UNL Tournament Code Generator</h1>
      
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Team List</h2>
          <button
            onClick={() => setShowAddTeam(!showAddTeam)}
            className={`${
              showAddTeam
                ? 'bg-gray-500 hover:bg-gray-600'
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white px-4 py-2 rounded-md flex items-center transition-colors duration-200`}
          >
            {showAddTeam ? (
              <X size={20} className="mr-2" />
            ) : (
              <Plus size={20} className="mr-2" />
            )}
            {showAddTeam ? 'Cancel' : 'Add New Team'}
          </button>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {teams.map((team) => (
            <div
              key={team.id}
              className="px-4 py-3 rounded-md text-white font-semibold cursor-pointer flex items-center justify-between relative group"
              style={{ backgroundColor: team.color }}
              onClick={() => handleTeamClick(team)}
              draggable
              onDragStart={(e) => handleDragStart(e, team)}
            >
              <div className="flex items-center flex-grow">
                <TeamLogo team={team} />
                <div className="flex flex-col">
                  <span className="text-lg font-bold px-2">{team.name}</span>
                  <span className="text-sm px-2">{team.fullName}</span>
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setTeamToDelete(team);
                }}
                className="bg-red-500 text-white p-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <X size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>

      {showAddTeam && (
        <div className="mb-6 p-4 border rounded-md">
          <h2 className="text-xl font-semibold mb-4">Add New Team</h2>
          <div className="space-y-4">
            <input
              type="text"
              value={newTeam}
              onChange={(e) => setNewTeam(e.target.value)}
              placeholder="Enter team abbreviation (2-3 letters)"
              className={`w-full px-3 py-2 border rounded-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
            />
            <input
              type="text"
              value={newTeamFullName}
              onChange={(e) => setNewTeamFullName(e.target.value)}
              placeholder="Enter full team name"
              className={`w-full px-3 py-2 border rounded-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
            />
            <div className="flex items-center">
              <input
                type="color"
                value={newTeamColor}
                onChange={(e) => setNewTeamColor(e.target.value)}
                className="mr-2"
              />
              <input
                type="text"
                value={newTeamColor}
                onChange={(e) => setNewTeamColor(e.target.value)}
                className={`w-full px-3 py-2 border rounded-md ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
              />
            </div>
            <div className="relative">
              <input
                type="file"
                onChange={handleLogoChange}
                className="hidden"
                id="logo-upload"
              />
              <label
                htmlFor="logo-upload"
                className={`flex items-center justify-center w-full px-4 py-2 border border-dashed rounded-md cursor-pointer ${
                  isDarkMode ? 'bg-gray-700 text-white border-gray-500' : 'bg-gray-100 text-black border-gray-300'
                }`}
              >
                {newTeamLogoPreview ? (
                  <img src={newTeamLogoPreview} alt="Logo preview" className="w-16 h-16 object-cover rounded-md" />
                ) : (
                  <div className="flex flex-col items-center">
                    <Upload size={24} className="mb-2" />
                    <span>Choose logo file</span>
                  </div>
                )}
              </label>
            </div>
            <button
              onClick={addTeam}
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
            >
              Add Team
            </button>
          </div>
        </div>
      )}

      <div className="flex mb-6 space-x-4">
        <div 
          className="w-1/2 bg-blue-100 p-4 rounded-lg"
          onDragOver={handleDragOver}
          onDrop={() => handleDrop('blue')}
        >
          <h2 className="text-xl font-semibold mb-2 text-blue-800">Blue Side</h2>
          {blueTeam ? (
            <div 
              className="px-3 py-2 rounded-md text-white font-semibold flex items-center"
              style={{ backgroundColor: blueTeam.color }}
            >
              <TeamLogo team={blueTeam} size="w-16 h-16" />
              <span className="text-lg font-bold px-2">{blueTeam.name}</span>
            </div>
          ) : (
            <div className="h-20 border-2 border-dashed border-blue-500 rounded-md"></div>
          )}
        </div>
        <div 
          className="w-1/2 bg-red-100 p-4 rounded-lg"
          onDragOver={handleDragOver}
          onDrop={() => handleDrop('red')}
        >
          <h2 className="text-xl font-semibold mb-2 text-red-800">Red Side</h2>
          {redTeam ? (
            <div 
              className="px-3 py-2 rounded-md text-white font-semibold flex items-center"
              style={{ backgroundColor: redTeam.color }}
            >
              <TeamLogo team={redTeam} size="w-16 h-16" />
              <span className="text-lg font-bold px-2">{redTeam.name}</span>
            </div>
          ) : (
            <div className="h-20 border-2 border-dashed border-red-500 rounded-md"></div>
          )}
        </div>
      </div>

      <button
        onClick={generateCode}
        className="w-full bg-green-500 text-white py-3 rounded-md hover:bg-green-600 mb-4 text-xl font-semibold"
        disabled={!blueTeam || !redTeam}
      >
        Generate Tournament Code
      </button>

      {tournamentCode && (
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-2">Tournament Code:</h2>
          <p className={`p-4 rounded-md text-2xl font-mono ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>{tournamentCode}</p>
        </div>
      )}

      {teamToDelete && (
        <ConfirmationModal
          team={teamToDelete}
          onConfirm={() => deleteTeam(teamToDelete.id)}
          onCancel={() => setTeamToDelete(null)}
        />
      )}
    </div>
  );
};

export default UNLAdminTournamentCodeGenerator;