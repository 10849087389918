import React, { useState, useEffect, useCallback } from 'react';
import { Plus, Minus, Users, RefreshCw, Play, X } from 'lucide-react';

const AdminTestingPage = ({ socket, isDarkMode, queueInfo }) => {
  const [queueState, setQueueState] = useState({ players: 0, playerTimes: {} });
  const [usersInQueue, setUsersInQueue] = useState([]);
  const [matchmakingLogs, setMatchmakingLogs] = useState([]);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [currentMatch, setCurrentMatch] = useState(null);
  const [acceptedPlayers, setAcceptedPlayers] = useState(0);
  const [mapType, setMapType] = useState(queueInfo?.mapType || "SUMMONERS_RIFT");

  useEffect(() => {
    if (queueInfo && queueInfo.mapType) {
      setMapType(queueInfo.mapType);
    }
  }, [queueInfo]);

  useEffect(() => {
    socket.on('queueUpdate', (newQueueState) => {
      setQueueState(newQueueState);
      if (newQueueState.players !== queueState.players) {
        logMatchmaking(`Queue updated: ${newQueueState.players} players in queue`);
      }
    });

    socket.on('fullQueueState', (fullState) => {
      setUsersInQueue(fullState);
    });

    socket.on('adminMatchFound', (matchData) => {
      setCurrentMatch(matchData);
      setShowMatchModal(true);
      setAcceptedPlayers(0);
      logMatchmaking('Match found!');
    });

    socket.on('playerAccepted', (count) => {
      setAcceptedPlayers(count);
      logMatchmaking(`Player accepted. Total accepted: ${count}`);
    });

    socket.on('queueReset', () => {
      logMatchmaking('Queue has been reset by admin');
    });

    socket.on('mapTypeChanged', (newMapType) => {
      setMapType(newMapType);
      logMatchmaking(`Map type changed to: ${newMapType}`);
    });

    return () => {
      socket.off('queueUpdate');
      socket.off('fullQueueState');
      socket.off('adminMatchFound');
      socket.off('playerAccepted');
      socket.off('queueReset');
      socket.off('mapTypeChanged');
    };
  }, [socket, queueState.players]);

  const logMatchmaking = useCallback((message) => {
    setMatchmakingLogs(prev => [...prev, { time: new Date().toISOString(), message }]);
  }, []);

  const addTestUser = useCallback(() => {
    const id = Date.now().toString();
    const roles = ['Top', 'Jungle', 'Middle', 'Bottom', 'Support'];
    const newUser = {
      puuid: `TEST_${id}`,
      gameName: `TestUser${id.slice(-4)}`,
      tagLine: `#${Math.floor(1000 + Math.random() * 9000)}`,
      primaryRole: roles[Math.floor(Math.random() * roles.length)],
      secondaryRole: roles[Math.floor(Math.random() * roles.length)],
    };
    socket.emit('adminAddUser', newUser);
    logMatchmaking(`Added test user: ${newUser.gameName}#${newUser.tagLine}`);
  }, [socket, logMatchmaking]);

  const removeUser = useCallback((puuid) => {
    socket.emit('adminRemoveUser', puuid);
    logMatchmaking(`Removed user with PUUID: ${puuid}`);
  }, [socket, logMatchmaking]);

  const fillQueue = useCallback(() => {
    socket.emit('adminFillQueue');
    logMatchmaking('Admin filled the queue');
  }, [socket, logMatchmaking]);

  const resetQueue = useCallback(() => {
    socket.emit('adminResetQueue');
    logMatchmaking('Admin reset the queue');
  }, [socket, logMatchmaking]);

  const acceptMatch = useCallback((puuid) => {
    socket.emit('adminAcceptMatch', puuid);
    logMatchmaking(`Admin accepted match for user with PUUID ${puuid}`);
  }, [socket, logMatchmaking]);

  const acceptAllMatches = useCallback(() => {
    if (currentMatch) {
      currentMatch.forEach(player => acceptMatch(player.puuid));
      setShowMatchModal(false);
      logMatchmaking('Admin accepted match for all players');
    }
  }, [currentMatch, acceptMatch, logMatchmaking]);

  const toggleMapType = () => {
    const newMapType = mapType === "SUMMONERS_RIFT" ? "HOWLING_ABYSS" : "SUMMONERS_RIFT";
    socket.emit('changeMapType', newMapType);
  };

  return (
    <div className={`container mx-auto p-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h1 className="text-3xl font-bold mb-6">Admin Testing Page</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 mb-6`}>
            <h2 className="text-2xl font-bold mb-4">Queue Management</h2>
            <div className="flex items-center justify-between mb-4">
              <div className={`${isDarkMode ? 'bg-blue-900 border-blue-700 text-blue-200' : 'bg-blue-100 border-blue-500 text-blue-700'} border-l-4 p-4 flex-grow mr-4`}>
                <div className="flex items-center">
                  <Users className="h-6 w-6 mr-2" />
                  <div>
                    <p className="font-bold">Current Queue Status</p>
                    <p>Players in Queue: {queueState.players}</p>
                  </div>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={addTestUser}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  <Plus size={20} className="mr-2" /> Add User
                </button>
                <button
                  onClick={fillQueue}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  <Play size={20} className="mr-2" /> Fill Queue
                </button>
                <button
                  onClick={resetQueue}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center"
                >
                  <RefreshCw size={20} className="mr-2" /> Reset Queue
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between mb-4">
              <div className={`${isDarkMode ? 'bg-blue-900 border-blue-700 text-blue-200' : 'bg-blue-100 border-blue-500 text-blue-700'} border-l-4 p-4 flex-grow mr-4`}>
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-bold">Current Map Type</p>
                    <p>{mapType}</p>
                  </div>
                  <button
                    onClick={toggleMapType}
                    className={`${mapType === "SUMMONERS_RIFT" ? 'bg-blue-500 hover:bg-blue-600' : 'bg-purple-500 hover:bg-purple-600'} text-white font-bold py-2 px-4 rounded`}
                  >
                    Toggle Map Type
                  </button>
                </div>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className={`min-w-full ${isDarkMode ? 'bg-gray-700' : 'bg-white'}`}>
                <thead className={isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}>
                  <tr>
                    <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'} text-left`}>Game Name</th>
                    <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'} text-left`}>Primary Role</th>
                    <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'} text-left`}>Secondary Role</th>
                    <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'} text-left`}>Queue Time</th>
                    <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'} text-left`}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                {usersInQueue.map(user => (
                    <tr key={user.puuid} className={isDarkMode ? 'hover:bg-gray-600' : 'hover:bg-gray-50'}>
                      <td className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>{user.gameName}#{user.tagLine}</td>
                      <td className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>{user.primaryRole}</td>
                      <td className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>{user.secondaryRole}</td>
                      <td className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>{queueState.playerTimes[user.puuid] || 0}s</td>
                      <td className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>
                        <button
                          onClick={() => removeUser(user.puuid)}
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                        >
                          <X size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 mb-6`}>
            <h2 className="text-2xl font-bold mb-4">Matchmaking Logs</h2>
            <div className={`h-96 overflow-y-auto border rounded p-4 mb-4 ${isDarkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-200 bg-white'}`}>
              {matchmakingLogs.map((log, index) => (
                <div key={index} className="mb-1">
                  <span className={`${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mr-2`}>[{log.time}]</span>
                  <span>{log.message}</span>
                </div>
              ))}
            </div>
            <button
              onClick={() => setMatchmakingLogs([])}
              className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex items-center"
            >
              <RefreshCw size={20} className="mr-2" /> Clear Logs
            </button>
          </div>
        </div>
      </div>

      {showMatchModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6 max-w-4xl w-full`}>
            <h3 className="text-2xl font-bold mb-4">Match Found</h3>
            <p className="mb-4">Accepted Players: {acceptedPlayers} / 10</p>
            <div className="mb-4">
              <h4 className="font-bold mb-2">Players in Match:</h4>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                {currentMatch && currentMatch.map((player, index) => (
                  <div key={index} className={`${isDarkMode ? 'border-gray-600 bg-gray-700' : 'border-gray-200 bg-white'} border rounded p-2`}>
                    <p className="font-bold">{player.gameName}#{player.tagLine}</p>
                    <p>{player.primaryRole}/{player.secondaryRole}</p>
                    <button
                      onClick={() => acceptMatch(player.puuid)}
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded mt-2 w-full"
                    >
                      Accept
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={acceptAllMatches}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Accept All
              </button>
              <button
                onClick={() => setShowMatchModal(false)}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTestingPage;