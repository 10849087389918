import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogOut, Copy, CheckIcon, Menu, X, Sun, Moon, Trophy } from 'lucide-react';
import logoImage from '../assets/theQ.png';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import QueueSummary from './QueueSummary';

const NavBar = ({ userData, inQueue, queueInfo, handleLogout, isDarkMode, toggleTheme, isAuthenticated, isUNLMode, toggleUNLMode }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { copied, copyToClipboard } = useCopyToClipboard();

  const handleCopyUsername = () => {
    if (isAuthenticated && userData) {
      copyToClipboard(`${userData.gameName}#${userData.tagLine}`);
    }
  };

  const navbarStyle = isDarkMode
    ? 'bg-gray-900 text-white'
    : 'bg-white text-gray-900';

  const linkStyle = isDarkMode
    ? 'text-gray-300 hover:text-white'
    : 'text-gray-500 hover:text-gray-700';

  const Toggle = ({ checked, onChange }) => (
    <div 
      className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer ${
        checked ? 'bg-[#5b01b8]' : 'bg-gray-300'
      }`}
      onClick={onChange}
    >
      <div
        className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
          checked ? 'translate-x-7' : ''
        }`}
      />
    </div>
  );

  const NavLink = ({ to, children }) => (
    <Link to={to} className={`px-3 py-2 rounded-md text-sm font-medium ${linkStyle}`}>
      {children}
    </Link>
  );

  const MobileNavLink = ({ to, children }) => (
    <Link
      to={to}
      className={`block px-3 py-2 rounded-md text-base font-medium ${linkStyle}`}
      onClick={() => setMobileMenuOpen(false)}
    >
      {children}
    </Link>
  );

  const renderNavLinks = (isMobile = false) => {
    let links = [
      { to: "/leaderboard", label: "Leaderboard" },
      { to: "/match-history", label: "Match History" },
      { to: "/champion-statistics", label: "Champions" },
    ];

    if (!isUNLMode) {
      links.unshift({ to: "/", label: "Queue" });
    }

    if (isUNLMode) {
      links.unshift({ to: "/schedule", label: "Schedule" });
      links.splice(2, 0, { to: "/standings", label: "Standings" });
    }

    return links.map((link, index) => (
      isMobile ? (
        <MobileNavLink key={index} to={link.to}>{link.label}</MobileNavLink>
      ) : (
        <NavLink key={index} to={link.to}>{link.label}</NavLink>
      )
    ));
  };

  return (
    <nav className={`shadow-md ${navbarStyle}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <img className="h-8 w-auto" src={logoImage} alt="The Q Logo" />
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            {renderNavLinks()}
          </div>

          {/* Desktop User Info and Controls */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            <button
              onClick={toggleTheme}
              className={`p-2 rounded-full ${isUNLMode ? 'bg-[#5b01b8] text-white hover:bg-[#4a0196]' : isDarkMode ? 'bg-gray-700 text-yellow-300 hover:text-yellow-500' : 'bg-gray-200 text-gray-500 hover:text-gray-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
            </button>
            <div className="flex items-center space-x-2">
              <Trophy className={`h-5 w-5 ${isUNLMode ? 'text-white' : 'text-gray-400'}`} />
              <Toggle checked={isUNLMode} onChange={toggleUNLMode} />
              <span className={`text-sm ${isUNLMode ? 'text-white' : isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                UNL Mode
              </span>
            </div>
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                disabled={inQueue}
                className={`
                  inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                  text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  transition duration-150 ease-in-out
                  ${inQueue ? 'opacity-50 cursor-not-allowed' : ''}
                `}
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </button>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className={`inline-flex items-center justify-center p-2 rounded-md ${isUNLMode ? 'text-white hover:bg-[#5b01b8]' : isDarkMode ? 'text-gray-400 hover:text-white hover:bg-gray-700' : 'text-gray-400 hover:text-gray-500 hover:bg-gray-100'} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${mobileMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {renderNavLinks(true)}
          <button
            onClick={toggleTheme}
            className={`flex items-center w-full px-3 py-2 rounded-md text-base font-medium ${linkStyle}`}
          >
            {isDarkMode ? <Sun className="h-5 w-5 mr-2" /> : <Moon className="h-5 w-5 mr-2" />}
            {isDarkMode ? 'Light Mode' : 'Dark Mode'}
          </button>
          <div className="flex items-center justify-between px-3 py-2">
            <span className={`text-base font-medium ${linkStyle}`}>
              UNL Mode
            </span>
            <Toggle checked={isUNLMode} onChange={toggleUNLMode} />
          </div>
          {isAuthenticated && (
            <button
              onClick={handleLogout}
              disabled={inQueue}
              className={`
                flex items-center w-full px-3 py-2 rounded-md text-base font-medium
                text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                ${inQueue ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              <LogOut className="mr-2 h-5 w-5" />
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;