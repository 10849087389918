// config.js
const ENVIRONMENT = process.env.NODE_ENV || 'development';

const config = {
  development: {
    AUTH_URL : "http://local.thequeue.com:3001",
    WEBSOCKETS_URL : "http://local.thequeue.com:3002",
    DATABASE_URL  :"http://local.thequeue.com:3003",
    CALLBACK_URL : "http://local.thequeue.com:3004",
    UNL_API_URL  :"http://local.thequeue.com:3005",
    UNL_CALLBACK_URL : "http://local.thequeue.com:3006",
    UNL_DATABASE_URL : "http://local.thequeue.com:3007"

  },
  production: {
    AUTH_URL : "https://auth.thequeue.org",
    WEBSOCKETS_URL : "https://websockets.thequeue.org",
    DATABASE_URL  :"https://database.thequeue.org",
    CALLBACK_URL : "https://callback.thequeue.org",
    UNL_API_URL : "https://api.unl.thequeue.org",
    UNL_CALLBACK_URL : "https://callback.unl.thequeue.org",
    UNL_DATABASE_URL  :"https://database.unl.thequeue.org"
  }
};

module.exports = config[ENVIRONMENT];