import React, { useState, useEffect } from 'react';
import { Calendar, Clock, ChevronLeft, ChevronRight } from 'lucide-react';
import { fetchSchedule, fetchTeams, fetchMatches } from '../utils/api';
const config = require('../utils/config');

const SchedulePage = ({ isDarkMode, isUNLMode }) => {
  const [schedule, setSchedule] = useState([]);
  const [teams, setTeams] = useState({});
  const [matches, setMatches] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [scheduleData, teamsData, matchesData] = await Promise.all([
          fetchSchedule(),
          fetchTeams(),
          fetchMatches(isUNLMode)
        ]);

        setSchedule(scheduleData);
        
        const teamsObject = teamsData.reduce((acc, team) => {
          acc[team.name] = team;
          return acc;
        }, {});
        setTeams(teamsObject);

        const matchesObject = matchesData.reduce((acc, match) => {
          acc[`${match.blueTeamName}-${match.redTeamName}`] = match;
          return acc;
        }, {});
        setMatches(matchesObject);

        const uniqueWeeks = [...new Set(scheduleData.map(match => match.week))].sort((a, b) => a - b);
        setWeeks(uniqueWeeks);

        const today = new Date();
        const firstUnplayedWeek = getFirstUnplayedWeek(scheduleData, matchesObject, today);
        setCurrentWeek(firstUnplayedWeek);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };
    loadData();
  }, [isUNLMode]);

  const getFirstUnplayedWeek = (scheduleData, matchesObject, today) => {
    const firstUnplayedMatch = scheduleData.find(match => {
      const matchDate = new Date(match.date);
      const matchKey = `${match.team1}-${match.team2}`;
      return matchDate >= today || !matchesObject[matchKey];
    });
    return firstUnplayedMatch ? firstUnplayedMatch.week : Math.min(...scheduleData.map(match => match.week));
  };

  const getBackgroundColor = () => isDarkMode ? 'bg-gray-900' : 'bg-gray-100';
  const getTextColor = () => isDarkMode ? 'text-white' : 'text-gray-900';

  const formatDate = (dateString) => {
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderWeek = (weekNumber) => {
    const weekMatches = schedule.filter(match => match.week === weekNumber);
    return (
      <div className="space-y-4">
        {weekMatches.map((match, index) => {
          const matchKey = `${match.team1}-${match.team2}`;
          const matchResult = matches[matchKey];
          const isMatchPlayed = !!matchResult;
          const winningTeam = isMatchPlayed
            ? (matchResult.winningTeamId === 100 ? matchResult.blueTeamName : matchResult.redTeamName)
            : null;

          return (
            <div
              key={`${match.date}-${index}`}
              className={`rounded-lg shadow-lg overflow-hidden ${
                isDarkMode ? 'bg-gray-800' : 'bg-white'
              }`}
            >
              <div className={`p-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} flex justify-between items-center`}>
                <div className="flex items-center space-x-2">
                  <Calendar size={18} />
                  <span className="font-semibold">{formatDate(match.date)}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Clock size={18} />
                  <span>{match.time}</span>
                </div>
              </div>
              <div className="p-4 flex items-stretch">
                {renderTeam(match.team1, 'left', isMatchPlayed, winningTeam === match.team1)}
                <div className="flex-grow flex items-center justify-center">
                  {isMatchPlayed ? (
                    <span className="font-bold text-xl" style={{ color: teams[winningTeam]?.color }}>
                      {winningTeam} WIN
                    </span>
                  ) : (
                    <span className="font-bold text-xl">VS</span>
                  )}
                </div>
                {renderTeam(match.team2, 'right', isMatchPlayed, winningTeam === match.team2)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTeam = (teamName, align, isMatchPlayed, isWinner) => {
    const team = teams[teamName];
    if (!team) return <div className="flex-1">Team not found</div>;

    const opacityClass = isMatchPlayed && !isWinner ? 'opacity-50' : '';

    return (
      <div className={`flex-1 flex items-center space-x-3 ${align === 'right' ? 'flex-row-reverse space-x-reverse' : ''} ${opacityClass}`}>
        <img
          src={`${config.UNL_API_URL}${team.logo}`}
          alt={`${team.name} logo`}
          className="w-12 h-12 object-contain rounded-full border-2 border-gray-300"
        />
        <div className={`flex flex-col ${align === 'right' ? 'items-end' : 'items-start'}`}>
          <span 
            className="font-bold text-lg"
            style={{ color: team.color }}
          >
            {team.name}
          </span>
          <span className="text-xs opacity-75">{team.fullName}</span>
        </div>
      </div>
    );
  };

  const handlePrevWeek = () => {
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex > 0) {
      setCurrentWeek(weeks[currentIndex - 1]);
    }
  };

  const handleNextWeek = () => {
    const currentIndex = weeks.indexOf(currentWeek);
    if (currentIndex < weeks.length - 1) {
      setCurrentWeek(weeks[currentIndex + 1]);
    }
  };

  if (loading) return <div className={`text-center mt-8 ${getTextColor()}`}>Loading schedule...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className={`container mx-auto px-4 py-4 ${getTextColor()}`}>
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={handlePrevWeek}
          disabled={currentWeek === weeks[0]}
          className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'} ${currentWeek === weeks[0] ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ChevronLeft size={24} />
        </button>
        <h2 className="text-2xl font-bold">Week {currentWeek}</h2>
        <button
          onClick={handleNextWeek}
          disabled={currentWeek === weeks[weeks.length - 1]}
          className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-300 hover:bg-gray-400'} ${currentWeek === weeks[weeks.length - 1] ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <ChevronRight size={24} />
        </button>
      </div>
      {renderWeek(currentWeek)}
    </div>
  );
};

export default SchedulePage;