import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Copy, CheckIcon, Send, Users, MessageSquare, ArrowDown, Map } from 'lucide-react';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { fetchPlayerProfileIcon } from '../utils/api';

import DEFAULT_ICON from '../assets/theQ.png';

const PlayerIcon = ({ player }) => {
  return (
    <div className="relative">
      <img
        src={player.profileIconUrl || DEFAULT_ICON}
        alt={`${player.gameName} Profile`}
        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
        onError={(e) => {
          console.error(`Failed to load profile icon for ${player.gameName}:`, e);
          e.target.onerror = null;
          e.target.src = DEFAULT_ICON;
        }}
      />
      {player.assignedRole && (
        <img
          src={`../assets/roles/${player.assignedRole}.png`}
          alt={player.assignedRole}
          className="w-4 h-4 sm:w-5 sm:h-5 absolute bottom-0 right-0"
          onError={(e) => {
            console.error(`Failed to load role icon for ${player.gameName}:`, e);
            e.target.style.display = 'none';
          }}
        />
      )}
    </div>
  );
};

const TeamList = ({ players, teamColor, isDarkMode, mapType }) => (
  <div className={`space-y-2 sm:space-y-4 ${
    isDarkMode
      ? (teamColor === 'blue' ? 'bg-blue-900/50' : 'bg-red-900/50')
      : (teamColor === 'blue' ? 'bg-blue-100' : 'bg-red-100')
  } p-3 sm:p-5 rounded-lg border-2 ${
    teamColor === 'blue' ? 'border-blue-500' : 'border-red-500'
  }`}>
    <h3 className={`text-lg sm:text-xl font-semibold ${
      teamColor === 'blue' ? 'text-blue-500' : 'text-red-500'
    }`}>
      {teamColor === 'blue' ? 'Blue Team' : 'Red Team'}
    </h3>
    {players.map((player, index) => (
      <div key={index} className="flex items-center space-x-2 sm:space-x-3">
        <PlayerIcon player={player} />
        <span className={`text-xs sm:text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          {player.gameName}#{player.tagLine}
          {mapType === "SUMMONERS_RIFT" && player.assignedRole && ` - ${player.assignedRole}`}
        </span>
      </div>
    ))}
  </div>
);

const ChatMessage = ({ message, isSystemMessage, isDarkMode }) => {
  const getTeamColor = (teamColor) => {
    if (teamColor === 'blue') return 'text-blue-600';
    if (teamColor === 'red') return 'text-red-600';
    return isDarkMode ? 'text-gray-300' : 'text-gray-700'; // Default color if teamColor is undefined
  };

  const teamColor = getTeamColor(message.teamColor);
  const teamPrefix = message.teamColor ? `[${message.teamColor.charAt(0).toUpperCase() + message.teamColor.slice(1)} Team] ` : '';

  return (
    <div className={`py-1 ${isSystemMessage ? (isDarkMode ? 'text-gray-400' : 'text-gray-500') : ''} ${isDarkMode ? 'italic' : ''}`}>
      {isSystemMessage ? (
        <span className="text-xs sm:text-sm">{message.message}</span>
      ) : (
        <span className="text-xs sm:text-sm">
          <span className={`font-semibold ${teamColor}`}>{teamPrefix}{message.sender}: </span>
          <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>{message.message}</span>
        </span>
      )}
    </div>
  );
};

const LobbyPage = ({ 
  socket, 
  userData, 
  matchedPlayers, 
  onClose, 
  isDarkMode, 
  initialChatMessages = [],
  isTestLobby = false 
}) => {
  console.log('LobbyPage rendered with props:', { userData, matchedPlayers, isTestLobby });

  const { tournament_code } = useParams();
  const navigate = useNavigate();
  const [chatMessages, setChatMessages] = useState(initialChatMessages);
  const [currentMessage, setCurrentMessage] = useState('');
  const chatContainerRef = useRef(null);
  const { copied, copyToClipboard } = useCopyToClipboard();
  const [playersWithIcons, setPlayersWithIcons] = useState(matchedPlayers);
  const [userTeam, setUserTeam] = useState(() => {
    const userPlayer = matchedPlayers.find(player => player.puuid === userData.puuid);
    return userPlayer ? userPlayer.teamColor : null;
  });
  const [isAutoScrolling, setIsAutoScrolling] = useState(true);
  const [mapType, setMapType] = useState("HOWLING_ABYSS");

  useEffect(() => {
    if (!isTestLobby) {
      if (!tournament_code) {
        console.log('No tournament code, navigating to home');
        navigate('/');
        return;
      }

      console.log('Joining lobby:', tournament_code);
      socket.emit('joinLobby', tournament_code);

      const handleLobbyData = (data) => {
        console.log('Lobby data received:', data);
        setChatMessages(data.chatMessages || []);
        if (data.mapType) setMapType(data.mapType);
        if (data.players) {
          setPlayersWithIcons(data.players);
          const updatedUserPlayer = data.players.find(player => player.puuid === userData.puuid);
          if (updatedUserPlayer) {
            console.log('Updating user team:', updatedUserPlayer.teamColor);
            setUserTeam(updatedUserPlayer.teamColor);
          }
        }
      };

      const fetchProfileIcons = async (players) => {
        console.log('Fetching profile icons for players:', players);
        try {
          const versionResponse = await fetch('https://ddragon.leagueoflegends.com/api/versions.json');
          const versions = await versionResponse.json();
          const latestVersion = versions[0];

          const updatedPlayers = await Promise.all(players.map(async (player) => {
            if (player.gameName === 'Riot Tester') {
              return { ...player, profileIconUrl: DEFAULT_ICON };
            }

            try {
              const { profileIconId } = await fetchPlayerProfileIcon(player.puuid, player.tagLine);
              return {
                ...player,
                profileIconUrl: `https://ddragon.leagueoflegends.com/cdn/${latestVersion}/img/profileicon/${profileIconId}.png`
              };
            } catch (error) {
              console.error(`Failed to fetch profile icon for ${player.gameName}#${player.tagLine}:`, error);
              return { ...player, profileIconUrl: DEFAULT_ICON };
            }
          }));

          console.log('Updated players with icons:', updatedPlayers);
          setPlayersWithIcons(updatedPlayers);
        } catch (error) {
          console.error('Failed to fetch profile icons:', error);
          setPlayersWithIcons(players.map(player => ({ ...player, profileIconUrl: DEFAULT_ICON })));
        }
      };

      // const handleMatchStarted = (data) => {
      //   console.log('Match started event received:', data);
      //   setMapType(data.mapType);
      //   fetchProfileIcons(data.players);
      //   const userPlayer = data.players.find(player => player.puuid === userData.puuid);
      //   if (userPlayer) {
      //     console.log('User team set:', userPlayer.teamColor);
      //     setUserTeam(userPlayer.teamColor);
      //   }
      // };

      const handleChatMessage = (message) => {
        console.log('Chat message received:', message);
        setChatMessages(prevMessages => [...prevMessages, message]);
      };

      socket.on('lobbyData', handleLobbyData);
      socket.on('chatMessage', handleChatMessage);

      return () => {
        console.log('Cleaning up socket listeners');
        socket.off('lobbyData', handleLobbyData);
        socket.off('chatMessage', handleChatMessage);
      };
    }
  }, [tournament_code, navigate, socket, userData.puuid, isTestLobby, matchedPlayers]);

  useEffect(() => {
    if (chatContainerRef.current && isAutoScrolling) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages, isAutoScrolling]);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAutoScrolling(isAtBottom);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      setIsAutoScrolling(true);
    }
  };

  const sendChatMessage = (e) => {
    e.preventDefault();
    if (currentMessage.trim() !== '') {
      const newMessage = {
        tournament_code,
        message: currentMessage,
        sender: userData.gameName,
        teamColor: userTeam
      };
      console.log('Sending chat message:', newMessage);
      socket.emit('sendChatMessage', newMessage);
      //setChatMessages(prevMessages => [...prevMessages, newMessage]);
      setCurrentMessage('');
      scrollToBottom();
    }
  };

  const sendButtonColor = userTeam === 'blue' ? 'bg-blue-500 hover:bg-blue-600' : 'bg-red-500 hover:bg-red-600';

  const blueTeam = playersWithIcons.filter(player => player.teamColor === 'blue');
  const redTeam = playersWithIcons.filter(player => player.teamColor === 'red');

  console.log('Rendering lobby with state:', { 
    mapType, 
    userTeam, 
    blueTeam, 
    redTeam, 
    chatMessages 
  });

  return (
    <div className={`container mx-auto px-2 sm:px-4 py-4 sm:py-8 max-w-6xl flex flex-col ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <h2 className={`text-2xl sm:text-3xl font-bold mb-4 sm:mb-8 ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>Pre-Game Lobby</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-8 flex-grow">
        <div className="lg:col-span-2 flex flex-col">
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-3 sm:p-6 rounded-lg shadow-md flex-grow overflow-auto`}>
            <div className="flex items-center justify-between mb-3 sm:mb-6">
              <div className="flex items-center space-x-2">
                <Users className={`h-4 w-4 sm:h-5 sm:w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                <h3 className={`text-lg sm:text-xl font-semibold ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>Teams</h3>
              </div>
              <div className="flex items-center space-x-2">
                <Map className={`h-4 w-4 sm:h-5 sm:w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                <span className={`text-sm sm:text-base ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  {mapType === "SUMMONERS_RIFT" ? "Summoner's Rift" : "Howling Abyss"}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-6">
              <TeamList players={blueTeam} teamColor="blue" isDarkMode={isDarkMode} mapType={mapType} />
              <TeamList players={redTeam} teamColor="red" isDarkMode={isDarkMode} mapType={mapType} />
            </div>
          </div>
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-3 sm:p-6 rounded-lg shadow-md mt-4`}>
            <p className={`text-xs sm:text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'} mb-2`}>Tournament Code</p>
            <div className="flex items-center space-x-2">
              <code className={`${isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'} px-2 sm:px-4 py-1 sm:py-2 rounded-lg text-xs sm:text-sm font-mono flex-grow`}>{tournament_code}</code>
              <button
                className={`p-1 sm:p-2 rounded-lg transition-colors ${
                  copied 
                    ? 'bg-green-500 text-white' 
                    : isDarkMode 
                      ? 'bg-gray-700 hover:bg-gray-600 text-gray-300' 
                      : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                }`}
                onClick={() => copyToClipboard(tournament_code)}
              >
                {copied ? <CheckIcon className="h-4 w-4 sm:h-5 sm:w-5" /> : <Copy className="h-4 w-4 sm:h-5 sm:w-5" />}
              </button>
            </div>
          </div>
        </div>
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg p-3 sm:p-6 shadow-md flex flex-col h-[600px]`}>
          <div className="flex items-center space-x-2 mb-2 sm:mb-4">
            <MessageSquare className={`h-4 w-4 sm:h-5 sm:w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
            <h3 className={`text-lg sm:text-xl font-semibold ${isDarkMode ? 'text-gray-100' : 'text-gray-800'}`}>Lobby Chat</h3>
          </div>
          <div className="flex-grow flex flex-col overflow-hidden relative">
            <div
              ref={chatContainerRef}
              className="overflow-y-auto pr-2 space-y-1"
              onScroll={handleScroll}
            >
              {chatMessages.map((message, index) => (
                <ChatMessage key={index} message={message} isSystemMessage={message.type === 'system'} isDarkMode={isDarkMode} />
              ))}
            </div>
            {!isAutoScrolling && (
              <button
                onClick={scrollToBottom}
                className={`absolute bottom-2 left-1/2 transform -translate-x-1/2 ${
                  isDarkMode 
                    ? 'bg-gray-700 hover:bg-gray-600 text-gray-300' 
                    : 'bg-gray-200 hover:bg-gray-300 text-gray-600'
                } rounded-full p-2 transition-colors`}
              >
                <ArrowDown className="h-4 w-4" />
              </button>
            )}
          </div>
          <form onSubmit={sendChatMessage} className="flex space-x-2 mt-2">
            <input
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              placeholder="Type a message..."
              className={`flex-grow px-2 sm:px-4 py-1 sm:py-2 ${
                isDarkMode 
                  ? 'bg-gray-700 text-gray-200 focus:ring-blue-400' 
                  : 'bg-gray-100 text-gray-800 focus:ring-blue-500'
              } rounded-lg focus:outline-none focus:ring-2 text-xs sm:text-sm`}
            />
            <button
              type="submit"
              className={`px-2 sm:px-4 py-1 sm:py-2 ${sendButtonColor} text-white rounded-lg transition-colors`}
            >
              <Send className="h-4 w-4 sm:h-5 sm:w-5" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LobbyPage;