import { useState, useEffect, useCallback } from 'react';

export const useQueue = (socket, userData) => {
  const [inQueue, setInQueue] = useState(null);
  const [queueInfo, setQueueInfo] = useState({ players: 0, playerTimes: {}, primaryRole: '', secondaryRole: '', mapType: '' });
  const [matchFound, setMatchFound] = useState(false);
  const [matchedPlayers, setMatchedPlayers] = useState([]);
  const [acceptedPlayers, setAcceptedPlayers] = useState([]);
  const [countdownProgress, setCountdownProgress] = useState(100);
  const [preGameView, setPreGameView] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);

  const setupSocketListeners = useCallback(() => {
    const onQueueStatus = (status) => {
      console.log('Received queueStatus:', status);
      setInQueue(status.inQueue);
      if (status.inQueue) {
        const updatedQueueInfo = {
          ...status.queueInfo,
          primaryRole: status.primaryRole,
          secondaryRole: status.secondaryRole,
          mapType: status.currentMapType
        };
        setQueueInfo(updatedQueueInfo);
        localStorage.setItem('queueState', JSON.stringify({ inQueue: true, ...updatedQueueInfo }));
      } else {
        localStorage.removeItem('queueState');
      }
    };

    const onQueueUpdate = (updatedQueueInfo) => {
      console.log('Received queueUpdate:', updatedQueueInfo);
      setQueueInfo(prevInfo => {
        const newInfo = {
          ...prevInfo,
          ...updatedQueueInfo,
          playerTimes: {
            ...prevInfo.playerTimes,
            ...updatedQueueInfo.playerTimes
          }
        };
        localStorage.setItem('queueState', JSON.stringify({ inQueue: true, ...newInfo }));
        return newInfo;
      });
    };

    const onMatchFound = (players) => {
      console.log('Received matchFound event:', players);
      setMatchFound(true);
      setMatchedPlayers(players);
      setCountdownProgress(100);
      setAcceptedPlayers([]);
      localStorage.removeItem('queueState');
    };

    const onPlayerAccepted = (puuid) => {
      console.log('Player accepted:', puuid);
      setAcceptedPlayers(prev => [...prev, puuid]);
    };

    const onQueueReset = () => {
      console.log('Queue has been reset by admin');
      setInQueue(false);
      setQueueInfo({ players: 0, playerTimes: {}, primaryRole: '', secondaryRole: '' });
      setMatchFound(false);
      setPreGameView(false);
      localStorage.removeItem('queueState');
    };

    socket.on('queueStatus', onQueueStatus);
    socket.on('queueUpdate', onQueueUpdate);
    socket.on('matchFound', onMatchFound);
    socket.on('playerAccepted', onPlayerAccepted);
    socket.on('queueReset', onQueueReset);

    return () => {
      socket.off('queueStatus', onQueueStatus);
      socket.off('queueUpdate', onQueueUpdate);
      socket.off('matchFound', onMatchFound);
      socket.off('playerAccepted', onPlayerAccepted);
      socket.off('queueReset', onQueueReset);
    };
  }, []);

  useEffect(() => {
    if (socket && userData && userData.puuid) {
      const handleConnect = () => {
        console.log('Connected to server');
        setIsConnected(true);
        const cleanup = setupSocketListeners();
        socket.emit('register', userData.puuid);
        const savedQueueState = JSON.parse(localStorage.getItem('queueState'));
        if (savedQueueState && savedQueueState.inQueue) {
          console.log('Rejoining queue with saved state:', savedQueueState);
          socket.emit('rejoinQueue', savedQueueState);
        } else {
          socket.emit('checkQueueStatus', userData.puuid);
        }
        return cleanup;
      };

      const handleDisconnect = (reason) => {
        console.log('Disconnected from server:', reason);
        setIsConnected(false);
      };

      const connectCleanup = handleConnect();
      
      socket.on('connect', handleConnect);
      socket.on('disconnect', handleDisconnect);

      return () => {
        connectCleanup();
        socket.off('connect', handleConnect);
        socket.off('disconnect', handleDisconnect);
      };
    }
  }, [socket, userData, setupSocketListeners]);

  useEffect(() => {
    let timer;
    if (matchFound) {
      timer = setInterval(() => {
        setCountdownProgress(prev => {
          if (prev <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 150);
    }
    return () => clearInterval(timer);
  }, [matchFound]);

  useEffect(() => {
    if (countdownProgress === 0) {
      setMatchFound(false);
      setPreGameView(true);
    }
  }, [countdownProgress]);

  const handleQueueJoin = useCallback((primaryRole, secondaryRole) => {
    if (socket && userData && userData.puuid) {
      console.log('Joining queue with roles:', primaryRole, secondaryRole);
      const queueState = { ...userData, primaryRole, secondaryRole, inQueue: true };
      socket.emit('joinQueue', queueState);
      setQueueInfo(prevInfo => ({
        ...prevInfo,
        primaryRole,
        secondaryRole
      }));
      setInQueue(true);
      localStorage.setItem('queueState', JSON.stringify(queueState));
    }
  }, [socket, userData]);

  const handleQueueLeave = useCallback(() => {
    if (socket && userData && userData.puuid) {
      console.log('Leaving queue');
      socket.emit('leaveQueue', userData.puuid);
      setInQueue(false);
      setMatchFound(false);
      setPreGameView(false);
      setQueueInfo({ players: 0, playerTimes: {}, primaryRole: '', secondaryRole: '' });
      localStorage.removeItem('queueState');
    }
  }, [socket, userData]);

  const handleAcceptMatch = useCallback(() => {
    if (userData && userData.puuid) {
      console.log('Accepting match');
      socket.emit('acceptMatch', userData.puuid);
      setAcceptedPlayers(prev => [...prev, userData.puuid]);
    }
  }, [userData, socket]);

  const handleDeclineMatch = useCallback(() => {
    if (userData && userData.puuid) {
      console.log('Declining match');
      setMatchFound(false);
      handleQueueLeave();
      socket.emit('declineMatch', userData.puuid);
    }
  }, [handleQueueLeave, userData, socket]);

  const getQueueTime = useCallback(() => {
    if (userData && userData.puuid && queueInfo.playerTimes) {
      return queueInfo.playerTimes[userData.puuid] || 0;
    }
    return 0;
  }, [userData, queueInfo.playerTimes]);

  return {
    inQueue,
    queueInfo,
    matchFound,
    matchedPlayers,
    acceptedPlayers,
    countdownProgress,
    preGameView,
    isConnected,
    handleQueueJoin,
    handleQueueLeave,
    handleAcceptMatch,
    handleDeclineMatch,
    setPreGameView,
    getQueueTime
  };
};