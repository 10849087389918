import axios from 'axios';
const config = require('../utils/config');

const getApiBaseUrl = (isUNLMode) => {
  const DATABASE_URL = isUNLMode ? config.UNL_DATABASE_URL : config.DATABASE_URL;
  return `${DATABASE_URL}/api`;
};

const handleApiError = (error) => {
  console.error('API Error:', error);
  if (error.response) {
    throw new Error(error.response.data.error || 'An error occurred');
  } else if (error.request) {
    throw new Error('No response received from server');
  } else {
    throw new Error('Error setting up the request');
  }
};

export const fetchPlayers = async (isUNLMode) => {
  try {
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/players`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchStandings = async () => {
  const response = await fetch(`${getApiBaseUrl(true)}/standings`);
  if (!response.ok) {
    throw new Error('Failed to fetch standings');
  }
  return response.json();
};

const parseMatchData = (match) => {
  return {
    ...match,
    team1Champions: Array.isArray(match.team1Champions) 
      ? match.team1Champions 
      : JSON.parse(match.team1Champions),
    team2Champions: Array.isArray(match.team2Champions)
      ? match.team2Champions
      : JSON.parse(match.team2Champions),
    participants: Array.isArray(match.participants)
      ? match.participants
      : JSON.parse(match.participants)
  };
};

export const fetchMatches = async (isUNLMode, puuid = null) => {
  try {
    let url = `${getApiBaseUrl(isUNLMode)}/matches`;
    
    if (puuid) {
      url = `${getApiBaseUrl(isUNLMode)}/players/${puuid}/matches`;
    }
    
    const response = await axios.get(url);
    
    // Parse the response data
    const parsedMatches = Array.isArray(response.data) 
      ? response.data.map(parseMatchData)
      : [parseMatchData(response.data)];

    return parsedMatches;
  } catch (error) {
    console.error('Error fetching matches:', error);
    return []; // Return an empty array in case of error
  }
};

export const fetchMatchDetails = async (isUNLMode, matchId) => {
  try {
    if (!matchId) {
      throw new Error('Match ID is undefined');
    }
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/matches/${matchId}`);
    if (!response.data || !response.data.participants) {
      throw new Error('Invalid match data received');
    }
    return response.data;
  } catch (error) {
    console.error(`Error fetching match details for match ID ${matchId}:`, error);
    throw error;
  }
};

export const fetchPlayerProfile = async (isUNLMode, puuid) => {
  try {
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/players/${puuid}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPlayerStats = async (isUNLMode, puuid) => {
  try {
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/players/${puuid}/stats`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPlayerGameName = async (isUNLMode, puuid) => {
  try {
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/players/${puuid}/gameName`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPlayerProfileIcon = async (isUNLMode, puuid) => {
  try {
    const response = await axios.get(`${getApiBaseUrl(isUNLMode)}/players/${puuid}/profileIcon`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchSchedule = async () => {
  try {
    const response = await fetch(`${getApiBaseUrl(true)}/schedule`);
    if (!response.ok) {
      throw new Error('Failed to fetch schedule');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching schedule:', error);
    throw error;
  }
};

export const fetchTeams = async () => {
  try {
    const response = await fetch(`${getApiBaseUrl(true)}/teams`);
    if (!response.ok) {
      throw new Error('Failed to fetch teams');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching teams:', error);
    throw error;
  }
};

export default {
  fetchPlayers,
  fetchMatches,
  fetchMatchDetails,
  fetchPlayerProfile,
  fetchPlayerStats,
  fetchPlayerGameName,
  fetchPlayerProfileIcon,
};