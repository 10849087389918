import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Clock, Calendar, Shield } from 'lucide-react';
import { formatDateTime, formatDuration } from '../utils/utils';
import ChampionIcons from './ChampionIcons';
import MatchDetails from './MatchDetails';

const MatchItemSkeleton = ({ isDarkMode }) => (
  <div className={`w-full rounded-lg shadow-md overflow-hidden border-l-4 ${
    isDarkMode ? 'border-gray-600 bg-gray-800' : 'border-gray-300 bg-white'
  } animate-pulse`}>
    <div className="p-4">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4">
        <div className="flex items-center space-x-2 sm:space-x-4 w-full sm:w-auto">
          <div className={`w-24 h-6 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded`}></div>
          <div className="flex-grow sm:flex-grow-0">
            <div className="flex space-x-1">
              {[...Array(5)].map((_, index) => (
                <div key={index} className={`w-8 h-8 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full`}></div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-end gap-2 w-full sm:w-auto">
          {[...Array(3)].map((_, index) => (
            <div key={index} className={`w-24 h-6 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full`}></div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const MatchItem = ({ match, expandedMatch, handleMatchExpand, handlePlayerClick, playerNames, gameVersion, isDarkMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isExpanded = expandedMatch && expandedMatch.matchId === match.matchId;
  const blueTeamWin = match.winningTeamId === 100;
  const winningTeamName = blueTeamWin ? match.blueTeamName : match.redTeamName;

  const handleExpand = async () => {
    if (!isExpanded) {
      setIsLoading(true);
      await handleMatchExpand(match);
    } else {
      await handleMatchExpand(match);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <MatchItemSkeleton isDarkMode={isDarkMode} />;
  }

  return (
    <div className={`select-none w-full rounded-lg shadow-md overflow-hidden border-l-4 ${
      isDarkMode
        ? (blueTeamWin ? 'border-blue-500 bg-gray-800' : 'border-red-500 bg-gray-800')
        : (blueTeamWin ? 'border-blue-500 bg-white' : 'border-red-500 bg-white')
    } transition-all duration-300 ease-in-out ${isExpanded ? 'shadow-lg' : ''}`}>
      <div 
        className={`p-4 cursor-pointer transition-colors duration-150 ${
          isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
        }`}
        onClick={handleExpand}
      >
        {/* New: Team match-up display */}
        <div className={`font-bold mb-2 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
          {match.blueTeamName} VS {match.redTeamName}
        </div>
        
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center space-x-2 sm:space-x-4 w-full sm:w-auto">
            <div className={`text-lg font-semibold ${blueTeamWin ? 'text-blue-600' : 'text-red-600'} flex items-center whitespace-nowrap`}>
              <span className="hidden sm:inline">{winningTeamName} Win</span>
              <span className="sm:hidden">{winningTeamName}</span>
            </div>
            <div className="flex-grow sm:flex-grow-0">
              <ChampionIcons match={match} gameVersion={gameVersion} />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-end gap-2 text-sm text-gray-500 w-full sm:w-auto">
            <div className={`hidden sm:flex items-center rounded-full px-3 py-1 ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
            }`}>
              <Shield className="w-4 h-4 mr-2 text-green-500 flex-shrink-0" />
              <span className="whitespace-nowrap">ID: {match.matchId}</span>
            </div>
            <div className={`flex items-center rounded-full px-3 py-1 ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
            }`}>
              <Clock className="w-4 h-4 mr-2 text-blue-500 flex-shrink-0" />
              <span className="whitespace-nowrap">{formatDuration(match.gameDuration)}</span>
            </div>
            <div className={`flex items-center rounded-full px-3 py-1 ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
            }`}>
              <Calendar className="w-4 h-4 mr-2 text-purple-500 flex-shrink-0" />
              <span className="whitespace-nowrap">{formatDateTime(match.gameCreation)}</span>
            </div>
            <div className={`rounded-full p-1 ml-auto sm:ml-0 ${
              isDarkMode ? 'bg-gray-700' : 'bg-gray-100'
            }`}>
              {isExpanded ? <ChevronUp className="w-5 h-5 text-gray-600" /> : <ChevronDown className="w-5 h-5 text-gray-600" />}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
          <MatchDetails
            match={expandedMatch}
            handlePlayerClick={handlePlayerClick}
            playerNames={playerNames}
            gameVersion={gameVersion}
            isDarkMode={isDarkMode}
          />
        </div>
      )}
    </div>
  );
};

export default MatchItem;