import React, { useState, useMemo } from 'react';
import { useMatchHistory } from '../hooks/useMatchHistory';
import MatchList from './MatchList';
import Pagination from './Pagination';

const MatchHistoryPage = ({ puuid = null, isDarkMode, isUNLMode }) => {
  const [activeTab, setActiveTab] = useState('SUMMONERS_RIFT');
  
  const {
    loading,
    matches,
    currentPage,
    totalPages,
    handleMatchExpand,
    handlePlayerClick,
    setCurrentPage,
    expandedMatch,
    playerNames,
    gameVersion,
  } = useMatchHistory(puuid, isUNLMode);

  const filteredMatches = useMemo(() => {
    if (isUNLMode) {
      return matches; // All UNL games are considered SUMMONERS_RIFT
    }
    return matches.filter(match => (match.map || 'SUMMONERS_RIFT') === activeTab);
  }, [matches, activeTab, isUNLMode]);

  const currentMatches = filteredMatches.slice((currentPage - 1) * 10, currentPage * 10);

  const tabs = [
    { id: 'SUMMONERS_RIFT', label: "Summoner's Rift" },
    { id: 'HOWLING_ABYSS', label: 'Howling Abyss' },
  ];

  return (
    <div className={`container rounded mx-auto px-4 py-4 flex flex-col`}>
      {!isUNLMode && (
        <div className="container mx-auto px-4 py-1 mb-4">
          <div className={`flex space-x-1 rounded-xl p-1 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                  ${activeTab === tab.id 
                    ? (isDarkMode ? 'bg-gray-600 text-white' : 'bg-white text-blue-700 shadow') 
                    : (isDarkMode ? 'text-gray-400 hover:bg-gray-600 hover:text-white' : 'text-gray-700 hover:bg-white/[0.12] hover:text-blue-700')
                  }
                  transition-all duration-200 ease-in-out
                `}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      )}
      
      <div className="flex-grow overflow-auto">
        <div className="container mx-auto px-4 py-4">
          <MatchList
            matches={currentMatches}
            loading={loading}
            expandedMatch={expandedMatch}
            handleMatchExpand={handleMatchExpand}
            handlePlayerClick={handlePlayerClick}
            playerNames={playerNames}
            gameVersion={gameVersion}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>
      <div className="mt-auto pb-8">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredMatches.length / 10)}
          setCurrentPage={setCurrentPage}
          isDarkMode={isDarkMode}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default MatchHistoryPage;