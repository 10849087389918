import React, { useState, useEffect } from 'react';
import { Trophy, Medal, Clock } from 'lucide-react';
import { fetchStandings } from '../utils/api';
const config = require('../utils/config');

const StandingsPage = ({ isDarkMode }) => {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadStandings = async () => {
      try {
        const standingsData = await fetchStandings();
        const sortedStandings = standingsData.sort((a, b) => {
          if (b.wins !== a.wins) {
            return b.wins - a.wins; // Sort by wins (descending)
          } else {
            return a.losses - b.losses; // If wins are equal, sort by losses (ascending)
          }
        });
        setStandings(sortedStandings);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching standings:', err);
        setError('Failed to load standings. Please try again later.');
        setLoading(false);
      }
    };
    loadStandings();
  }, []);

  const getBackgroundColor = () => isDarkMode ? 'bg-gray-900' : 'bg-gray-100';
  const getTextColor = () => isDarkMode ? 'text-white' : 'text-gray-900';

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const calculateWinRate = (team) => {
    if (team.gamesPlayed === 0) {
      return "-";
    }
    return `${((team.wins / team.gamesPlayed) * 100).toFixed(1)}%`;
  };

  if (loading) return <div className={`text-center mt-8 ${getTextColor()}`}>Loading standings...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className={`container mx-auto flex-col px-4 py-4 ${getTextColor()}`}>
      <div className="max-w-5xl mx-auto">
        {standings.map((team, index) => (
          <div
            key={team.teamName}
            className="rounded-lg shadow-md overflow-hidden mb-4"
            style={{ backgroundColor: team.teamColor + '22' }}
          >
            <div className="p-2 md:p-3 flex items-center space-x-2 md:space-x-3" style={{ backgroundColor: team.teamColor + '44' }}>
              <div className="w-8 md:w-10 h-8 md:h-10 flex-shrink-0 flex items-center justify-center">
                <span className={`text-lg md:text-xl font-bold ${getTextColor()} opacity-60`}>#{index + 1}</span>
              </div>
              <img
                src={`${config.UNL_API_URL}${team.teamLogo}`}
                alt={`${team.teamName} logo`}
                className="w-10 h-10 md:w-12 md:h-12 object-contain rounded"
              />
              <div className="flex-grow">
                <h2 className="text-lg md:text-xl font-bold leading-tight" style={{ color: team.teamColor }}>{team.teamName}</h2>
                <p className={`text-xs md:text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>{team.teamFullName}</p>
              </div>
              <div className="text-right">
                <p className="text-lg md:text-xl font-bold leading-tight">{team.wins} - {team.losses}</p>
                <p className={`text-xs md:text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Win - Loss</p>
              </div>
            </div>
            <div className={`p-2 md:p-3 grid grid-cols-3 gap-2 md:gap-3 text-xs md:text-sm ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}`}>
              <div className="flex items-center space-x-1 md:space-x-2">
                <Trophy size={16} />
                <span>Games: {team.gamesPlayed}</span>
              </div>
              <div className="flex items-center space-x-1 md:space-x-2">
                <Medal size={16} />
                <span>Win Rate: {calculateWinRate(team)}</span>
              </div>
              <div className="flex items-center space-x-1 md:space-x-2">
                <Clock size={16} />
                <span>Time: {formatTime(team.timePlayed)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StandingsPage;