import React from 'react';
import { getChampionIconUrl } from '../utils/utils';

const ChampionIcons = ({ match, gameVersion }) => {
  const blueTeamChampions = match.team1Champions;
  const redTeamChampions = match.team2Champions;

  const renderTeamIcons = (champions, borderColor) => (
    <div className="flex flex-wrap justify-center">
      {champions.map((championName, index) => (
        <img 
          key={`${borderColor}-${index}`}
          src={getChampionIconUrl(championName, gameVersion)}
          alt={championName || 'Unknown'}
          className={`w-6 h-6 sm:w-8 sm:h-8 rounded-full border-2 border-${borderColor}-400 bg-white -ml-1 sm:-ml-2 first:ml-0`}
          title={championName || 'Unknown'}
        />
      ))}
    </div>
  );

  return (
    <div className="flex items-center space-x-1 sm:space-x-2">
      {renderTeamIcons(blueTeamChampions, 'blue')}
      <span className="text-xs font-bold text-gray-500 mx-1">VS</span>
      {renderTeamIcons(redTeamChampions, 'red')}
    </div>
  );
};

export default ChampionIcons;