import React from 'react';
import MatchItem from './MatchItem';

const MatchListSkeleton = ({ count, isDarkMode }) => {
  return (
    <div className='flex flex-col mid-h-[calc(100vh-350px)] space-y-4'>
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className={`w-full h-24 rounded-lg shadow-md overflow-hidden border-l-4 ${
            isDarkMode ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'
          } animate-pulse`}
        >
          <div className="h-full flex px-4">
            <div className="flex items-center space-x-4">
              <div className={`w-16 h-16 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}></div>
              <div className="space-y-2">
                <div className={`h-4 w-24 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded`}></div>
                <div className={`h-4 w-32 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded`}></div>
              </div>
            </div>
            <div className={`h-8 w-24 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded`}></div>
          </div>
        </div>
      ))}
    </div>
  );
};

const MatchList = ({
  matches,
  loading,
  expandedMatch,
  handleMatchExpand,
  handlePlayerClick,
  playerNames,
  gameVersion,
  isDarkMode
}) => {
  if (loading) {
    return <MatchListSkeleton count={2} isDarkMode={isDarkMode} />;
  }

  return (
    <div className={`select-none flex flex-col min-h-[calc(100vh-350px)] ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'} space-y-2 p-2`}>
      {matches.map((match) => (
        <MatchItem
          key={match.matchId}
          match={match}
          expandedMatch={expandedMatch}
          handleMatchExpand={handleMatchExpand}
          handlePlayerClick={handlePlayerClick}
          playerNames={playerNames}
          gameVersion={gameVersion}
          isDarkMode={isDarkMode}
        />
      ))}
    </div>
  );
};

export default MatchList;