export const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };
  
  export const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  export const getChampionIconUrl = (championName, gameVersion) => {
    if (!gameVersion || !championName) return '';
    return `https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${championName}.png`;
  };
  
  export const orderTeamByRole = (team) => {
    const roleOrder = ['TOP', 'JUNGLE', 'MIDDLE', 'BOTTOM', 'UTILITY'];
    return roleOrder.map(role => team.find(p => p.role === role)).filter(Boolean);
  };