import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPlayers, fetchPlayerStats } from '../utils/api';
import { Search, ChevronUp, ChevronDown, Trophy } from 'lucide-react';

const LeaderboardPage = ({ isDarkMode, isUNLMode }) => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'rating', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('SUMMONERS_RIFT');
  const navigate = useNavigate();

  const tabs = [
    { id: 'SUMMONERS_RIFT', label: "Summoner's Rift" },
    { id: 'HOWLING_ABYSS', label: 'Howling Abyss' },
  ];

  useEffect(() => {
    const loadPlayers = async () => {
      try {
        const playersData = await fetchPlayers(isUNLMode);
        const playersWithStats = await Promise.all(playersData.map(async (player) => {
          const stats = await fetchPlayerStats(isUNLMode, player.puuid);
          return processPlayerStats(player, stats, isUNLMode);
        }));

        setPlayers(playersWithStats);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching players:', err);
        setError('Failed to load players. Please try again later.');
        setLoading(false);
      }
    };
    loadPlayers();
  }, [isUNLMode]);

  const processPlayerStats = (player, stats, isUNLMode) => {
    if (isUNLMode) {
      const overallStats = stats.overall_stats;
      return {
        ...player,
        ...overallStats,
        rank: 0,
        kda: calculateKDA(overallStats),
        csPerMin: calculateCSPerMin(overallStats),
        damagePerMin: calculateDamagePerMin(overallStats),
        winRate: calculateWinRate(overallStats.wins, overallStats.losses),
        totalGames: overallStats.gamesPlayed,
        kills: overallStats.kills,
        deaths: overallStats.deaths,
        assists: overallStats.assists,
        rating: overallStats.rating || 0
      };
    } else {
      return {
        ...player,
        SUMMONERS_RIFT: processMapStats(stats, 'SUMMONERS_RIFT'),
        HOWLING_ABYSS: processMapStats(stats, 'HOWLING_ABYSS'),
      };
    }
  };

  const processMapStats = (stats, mapType) => {
    const mapStats = stats.overall_stats.find(stat => stat.map === mapType) || {
      wins: 0,
      losses: 0,
      kills: 0,
      deaths: 0,
      assists: 0,
      cs: 0,
      totalDamageDealtToChampions: 0,
      soloKills: 0,
      pentaKills: 0,
      timePlayed: 0,
      gamesPlayed: 0,
      rating: 0
    };

    return {
      ...mapStats,
      rank: 0,
      kda: calculateKDA(mapStats),
      csPerMin: calculateCSPerMin(mapStats),
      damagePerMin: calculateDamagePerMin(mapStats),
      winRate: calculateWinRate(mapStats.wins, mapStats.losses),
      totalGames: mapStats.gamesPlayed,
      rating: mapStats.rating || 0
    };
  };

  const calculateKDA = (stats) => {
    const { kills, deaths, assists } = stats;
    return deaths === 0 ? kills + assists : parseFloat(((kills + assists) / deaths).toFixed(2));
  };

  const calculateCSPerMin = (stats) => {
    const { cs, timePlayed } = stats;
    return timePlayed === 0 ? 0 : parseFloat((cs / (timePlayed / 60)).toFixed(2));
  };

  const calculateDamagePerMin = (stats) => {
    const { totalDamageDealtToChampions, timePlayed } = stats;
    return timePlayed === 0 ? 0 : parseFloat((totalDamageDealtToChampions / (timePlayed / 60)).toFixed(2));
  };

  const calculateWinRate = (wins, losses) => {
    const totalGames = wins + losses;
    return totalGames > 0 ? parseFloat(((wins / totalGames) * 100).toFixed(2)) : 0;
  };

  const formatTime = (seconds) => {
    if (typeof seconds !== 'number') return '0h 0m';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const sortedPlayers = useMemo(() => {
    let sortableItems = isUNLMode 
      ? players 
      : players.map(player => ({
          ...player,
          ...player[activeTab]
        }));
    
    sortableItems = sortableItems.filter(player => player.totalGames > 0);
    
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        return 0;
      });
    }
    
    return sortableItems.map((player, index) => ({
      ...player,
      rank: sortConfig.direction === 'ascending' ? index + 1 : sortableItems.length - index
    }));
  }, [players, sortConfig, activeTab, isUNLMode]);

  const filteredPlayers = useMemo(() => {
    return sortedPlayers.filter(player =>
      player.gameName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedPlayers, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handlePlayerClick = (puuid) => {
    navigate(`/profile/${puuid}`);
  };

  const columns = [
    { key: 'rating', label: 'Rank' },
    { key: 'gameName', label: 'Player' },
    { key: 'totalGames', label: 'Games' },
    { key: 'winRate', label: 'Win %' },
    { key: 'kills', label: 'K' },
    { key: 'deaths', label: 'D' },
    { key: 'assists', label: 'A' },
    { key: 'kda', label: 'KDA' },
    { key: 'csPerMin', label: 'CS/m' },
    { key: 'damagePerMin', label: 'DMG/m' },
  ];

  const getBackgroundColor = () => {
    return isDarkMode ? 'bg-gray-900' : 'bg-gray-100';
  };

  const getTextColor = () => {
    return isDarkMode ? 'text-white' : 'text-gray-900';
  };

  const getHighlightColor = () => {
    return isDarkMode ? 'bg-gray-800' : 'bg-white';
  };

  if (loading) return <div className={`text-center mt-8 ${getTextColor()}`}>Loading players...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className={`container rounded mx-auto px-4 py-4 flex flex-col ${getTextColor()}`}>
      {!isUNLMode && (
        <div className="container mx-auto px-4 py-1 mb-4">
          <div className={`flex space-x-1 rounded-xl p-1 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                  ${activeTab === tab.id 
                    ? (isDarkMode ? 'bg-gray-600 text-white' : 'bg-white text-blue-700 shadow') 
                    : (isDarkMode ? 'text-gray-400 hover:bg-gray-600 hover:text-white' : 'text-gray-700 hover:bg-white/[0.12] hover:text-blue-700')
                  }
                  transition-all duration-200 ease-in-out
                `}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search players..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={`w-full p-3 pl-12 rounded-full shadow-md ${
            isDarkMode 
                ? 'bg-gray-800 text-white placeholder-gray-400' 
                : 'bg-white text-gray-900 placeholder-gray-500'
          } focus:outline-none transition duration-300`}
        />
        <Search className={`absolute left-4 top-1/2 transform -translate-y-1/2`} size={20} />
      </div>
      <div className="grid gap-2 md:hidden">
        {filteredPlayers.map((player) => (
          <div 
            key={player.puuid} 
            className={`p-2 rounded-lg shadow-md cursor-pointer ${
              isDarkMode 
                  ? 'bg-gray-800 hover:bg-gray-700' 
                  : 'bg-white hover:bg-gray-50'
            } transition duration-300`} 
            onClick={() => handlePlayerClick(player.puuid)}
          >
            <div className="flex justify-between items-center mb-1">
              <span className="font-bold text-sm">
                <Trophy className={`inline-block mr-1`} size={16} />
                #{player.rank} {player.gameName}
              </span>
            </div>
            <div className="grid grid-cols-3 gap-1 text-xs">
              <div>Games: <span className="font-semibold">{player.totalGames}</span></div>
              <div>Win %: <span className="font-semibold">{player.winRate.toFixed(0)}%</span></div>
              <div>KDA: <span className="font-semibold">{player.kda}</span></div>
              <div>K/D/A: <span className="font-semibold">{player.kills}/{player.deaths}/{player.assists}</span></div>
              <div>CS/m: <span className="font-semibold">{player.csPerMin}</span></div>
              <div>DMG/m: <span className="font-semibold">{player.damagePerMin}</span></div>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden md:block overflow-x-auto rounded-lg shadow-lg">
        <table className={`min-w-full ${getHighlightColor()}`}>
          <thead className='select-none'>
            <tr className={isDarkMode ? 'bg-gray-800' : 'bg-gray-200'}>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`px-3 py-2 text-left text-xs font-semibold uppercase tracking-wider cursor-pointer ${
                    isDarkMode 
                        ? 'text-gray-300 hover:text-white' 
                        : 'text-gray-600 hover:text-gray-900'
                  } transition duration-300`}
                  onClick={() => requestSort(column.key)}
                >
                  <div className="flex items-center">
                    {column.label}
                    {sortConfig.key === column.key && (
                      sortConfig.direction === 'ascending' ? <ChevronUp className="ml-1 h-3 w-3" /> : <ChevronDown className="ml-1 h-3 w-3" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={''}>
            {filteredPlayers.map((player, index) => (
              <tr
                key={player.puuid}
                className={`cursor-pointer ${
                  isDarkMode 
                      ? index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-750'
                      : index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                } hover:bg-opacity-80 transition duration-300`}
                onClick={() => handlePlayerClick(player.puuid)}
              >
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.rank}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm font-medium">{player.gameName}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.totalGames}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.winRate.toFixed(0)}%</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.kills}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.deaths}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.assists}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.kda}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.csPerMin}</td>
                <td className="px-3 py-3 whitespace-nowrap text-sm">{player.damagePerMin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {filteredPlayers.length === 0 && (
        <div className={`text-center mt-8 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          No players found matching your search.
        </div>
      )}
    </div>
  );
};

export default LeaderboardPage;