import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LogIn } from 'lucide-react';
const config = require('../utils/config');

const SignInPage = ({ setIsAuthenticated, setUserData, isDarkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("SignInPage mounted");
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    console.log("URL params:", { code, error });

    if (error) {
      console.log("Error in URL params");
      setError('Authentication failed. Please try again.');
      return;
    }

    if (code) {
      console.log("Code found in URL, initiating code exchange");
      handleCodeExchange(code);
    } else {
      console.log("No code found in URL");
    }
  }, [location]);

  const handleCodeExchange = async (code) => {
    console.log("handleCodeExchange started");
    setIsLoading(true);
    setError(null);

    try {
      console.log("Sending POST request to", `${config.AUTH_URL}/api/token`);
      const response = await fetch(`${config.AUTH_URL}/api/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Response data:', data);

      if (data && data.access_token) {
        console.log("Access token received, setting authentication");
        localStorage.setItem('riotToken', data.access_token);
        localStorage.setItem('userData', JSON.stringify({
          puuid: data.puuid,
          gameName: data.gameName,
          tagLine: data.tagLine
        }));
        setIsAuthenticated(true);
        setUserData({
          puuid: data.puuid,
          gameName: data.gameName,
          tagLine: data.tagLine
        });
        console.log("Navigating to home page");
        navigate('/');
      } else {
        console.log("No access token in response");
        throw new Error(data.error || 'No access token received in the response');
      }
    } catch (error) {
      console.error('Error during sign in:', error);
      setError(`An error occurred during sign in: ${error.message}`);
    } finally {
      setIsLoading(false);
      console.log("handleCodeExchange finished");
    }
  };

  const handleSignIn = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`${config.AUTH_URL}/api/signin-url`);
      if (!response.ok) {
        throw new Error('Failed to fetch sign-in URL');
      }
      const data = await response.json();
      window.location.href = data.signInUrl;
    } catch (error) {
      console.error('Error fetching sign-in URL:', error);
      setError('Failed to initiate sign in. Please try again.');
      setIsLoading(false);
    }
  };
  return (
    <div className="flex items-center justify-center min-h-[calc(100vh-4rem)] px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 bg-white shadow-2xl rounded-xl p-6 sm:p-10">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md" role="alert">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        )}

        <div className="mt-8 space-y-6">
          {isLoading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
            </div>
          ) : (
            <button
              onClick={handleSignIn}
              className="group relative w-full flex justify-center py-3 sm:py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150 ease-in-out"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12.534 21.77l-1.09-2.81 10.52.54-.451 4.5zM15.06 0L.307 6.969 2.59 17.471H5.6l-.52-7.512 1.027-.56 1.02 8.072h3.012l-.053-9.637 1.078-.566.998 10.203h3.064l-.053-11.163 1.078-.566.891 11.729h3.064L21.684 4.273z" />
                </svg>
              </span>
              Sign in with Riot Games
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignInPage;