import React, { useState, useEffect } from 'react';
import { LogIn } from 'lucide-react';
import RoleSelection from './RoleSelection';
import QueueStatus from './QueueStatus';
const config = require('../utils/config');

const MessageOfTheDay = ({ message, date, isDarkMode }) => (
  <div className={`${isDarkMode ? 'bg-gradient-to-r from-purple-900 to-indigo-900 text-white' : 'bg-gradient-to-r from-purple-100 to-indigo-100 text-gray-900'} shadow-lg rounded-lg p-6 mb-8 border-l-4 ${isDarkMode ? 'border-purple-500' : 'border-indigo-500'} transform hover:scale-105 transition-all duration-300`}>
    <h2 className="text-2xl font-bold mb-2 flex items-center">
      <span className="mr-2">📢</span> Announcement
    </h2>
    <p className={`${isDarkMode ? 'text-purple-200' : 'text-indigo-800'} text-lg mb-2`}>{message}</p>
  </div>
);

const CustomAlert = ({ title, description, isDarkMode }) => (
  <div className={`border-l-4 border-red-500 p-4 ${isDarkMode ? 'bg-red-900 text-red-100' : 'bg-red-100 text-red-900'} rounded-md mb-4`}>
    <h3 className="font-bold">{title}</h3>
    <p>{description}</p>
  </div>
);

const HomePage = ({
  socket,
  userData,
  inQueue,
  queueInfo,
  handleQueueJoin,
  handleQueueLeave,
  getQueueTime,
  isDarkMode,
  isAuthenticated,
  setIsAuthenticated,
  setUserData,
  isUNLMode,
  toggleUNLMode
}) => {
  const [primaryRole, setPrimaryRole] = useState('');
  const [secondaryRole, setSecondaryRole] = useState('');
  const [error, setError] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [messageOfTheDay, setMessageOfTheDay] = useState({ message: '', date: '' });
  const [mapType, setMapType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getMapColor = (mapType, isDarkMode) => {
    if (mapType === "SUMMONERS_RIFT") {
      return isDarkMode ? 'from-green-900 to-green-700' : 'from-green-600 to-green-400';
    } else {
      return isDarkMode ? 'from-blue-900 to-blue-700' : 'from-blue-400 to-blue-300';
    }
  };

  useEffect(() => {
    const fetchMessageOfTheDay = async () => {
      try {
        const response = await fetch(`${config.DATABASE_URL}/api/message-of-the-day`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Oops, we haven't received a JSON response!");
        }
        const data = await response.json();
        setMessageOfTheDay({ message: data.message, date: data.date });
      } catch (error) {
        console.error('Error fetching message of the day:', error);
      }
    };

    if (isAuthenticated) {
      fetchMessageOfTheDay();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (queueInfo && queueInfo.mapType) {
      setMapType(queueInfo.mapType);
      setIsLoading(false);
    }
  }, [queueInfo]);

  const handleQueue = () => {
    if (mapType === "HOWLING_ABYSS") {
      handleQueueJoin();
    } else {
      if (!primaryRole || (primaryRole !== 'Fill' && !secondaryRole)) {
        setError('Please select both primary and secondary roles, or choose Fill as primary role.');
        return;
      }
      setError('');
      handleQueueJoin(primaryRole, primaryRole === 'Fill' ? '' : secondaryRole);
    }
  };

  const handleRoleChange = (role, isPrimary) => {
    if (isPrimary) {
      setPrimaryRole(role);
      if (role === 'Fill') {
        setSecondaryRole('');
      } else if (role === secondaryRole) {
        setSecondaryRole('');
      }
    } else {
      if (role === primaryRole) {
        setPrimaryRole('');
      }
      setSecondaryRole(role);
    }
  };

  const handleResetRoles = () => {
    setPrimaryRole('');
    setSecondaryRole('');
    handleQueueLeave();
  };

  const handleSignIn = async () => {
    setIsSigningIn(true);
    setSignInError(null);

    try {
      const response = await fetch(`${config.AUTH_URL}/api/signin-url`);
      if (!response.ok) {
        throw new Error('Failed to fetch sign-in URL');
      }
      const data = await response.json();
      window.location.href = data.signInUrl;
    } catch (error) {
      console.error('Error fetching sign-in URL:', error);
      setSignInError('Failed to initiate sign in. Please try again.');
      setIsSigningIn(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-4rem)] px-4 sm:px-6 lg:px-8">
        <div className={`w-full max-w-md space-y-8 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'} shadow-2xl rounded-xl p-6 sm:p-10`}>
          <div>
            <h2 className="mt-2 text-center text-3xl font-extrabold">
              Welcome to The Queue
            </h2>
            <p className={`mt-2 text-center text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Sign in to start queuing for matches
            </p>
          </div>
          
          {signInError && (
            <div className={`${isDarkMode ? 'bg-red-900 text-red-200' : 'bg-red-100 text-red-700'} border-l-4 border-red-500 p-4 rounded-md`} role="alert">
              <p className="font-bold">Error</p>
              <p>{signInError}</p>
            </div>
          )}

          <div className="mt-8 space-y-6">
            {isSigningIn ? (
              <div className="flex justify-center items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
              </div>
            ) : (
              <button
                onClick={handleSignIn}
                className="group relative w-full flex justify-center py-3 sm:py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition duration-150 ease-in-out"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12.534 21.77l-1.09-2.81 10.52.54-.451 4.5zM15.06 0L.307 6.969 2.59 17.471H5.6l-.52-7.512 1.027-.56 1.02 8.072h3.012l-.053-9.637 1.078-.566.998 10.203h3.064l-.053-11.163 1.078-.566.891 11.729h3.064L21.684 4.273z" />
                  </svg>
                </span>
                Sign in with Riot Games
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (isUNLMode) {
    return (
      <div className={`w-full max-w-xl mx-auto px-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
        <CustomAlert 
          title="UNL Mode Active"
          description="The homepage for UNL mode is currently under maintenance. Please turn off UNL mode to access the regular queue."
          isDarkMode={isDarkMode}
        />
        {/* <button
          onClick={toggleUNLMode}
          className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          Turn Off UNL Mode
        </button> */}
      </div>
    );
  }

  return (
    <div className={`w-full max-w-xl mx-auto px-4 ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      {isAuthenticated && messageOfTheDay.message && (
        <MessageOfTheDay 
          message={messageOfTheDay.message} 
          date={messageOfTheDay.date} 
          isDarkMode={isDarkMode} 
        />
      )}
      {isLoading ? (
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-4 mb-4 flex justify-center items-center h-64`}>
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
        </div>
      ) : (
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg rounded-lg p-4 mb-4`}>
          <div className={`text-center py-2 px-4 rounded-md bg-gradient-to-r ${getMapColor(mapType, isDarkMode)} transition-all duration-300 ease-in-out transform`}>
            <h2 className="text-xl font-bold text-white">
              {mapType === "SUMMONERS_RIFT" ? "Summoner's Rift" : "Howling Abyss"}
            </h2>
          </div>
          
          {mapType === "SUMMONERS_RIFT" && !inQueue && (
            <div className="mt-4">
              <RoleSelection
                primaryRole={primaryRole}
                secondaryRole={secondaryRole}
                onRoleChange={handleRoleChange}
                isDarkMode={isDarkMode}
              />
              {error && <p className={`${isDarkMode ? 'text-red-400' : 'text-red-600'} mb-4`}>{error}</p>}
            </div>
          )}
          <div className="mt-4">
            <QueueStatus
              queueInfo={queueInfo}
              getQueueTime={getQueueTime}
              onCancelQueue={handleQueueLeave}
              onResetRoles={handleResetRoles}
              isDarkMode={isDarkMode}
              inQueue={inQueue}
              onJoinQueue={handleQueue}
              mapType={mapType}
              primaryRole={primaryRole}
              secondaryRole={secondaryRole}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;