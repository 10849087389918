import { useState, useCallback } from 'react';
import { Check, Copy } from 'lucide-react';

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(async (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }, []);

  const fallbackCopyTextToClipboard = useCallback((text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.cssText = 'position:fixed;top:0;left:0;opacity:0;';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } else {
        console.error('Fallback: Unable to copy');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }, []);

  const CopyButton = useCallback(({ text, className }) => (
    <button
      className={`px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded text-sm transition-all duration-300 ease-in-out ${className}`}
      onClick={() => copyToClipboard(text)}
    >
      {copied ? <Check className="h-4 w-4 text-green-500" /> : <Copy className="h-4 w-4" />}
    </button>
  ), [copied, copyToClipboard]);

  return { copied, copyToClipboard, CopyButton };
};