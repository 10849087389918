import React, { useState } from 'react';
import { X } from 'lucide-react';
import { formatTime } from '../utils/formatTime';

// Import role icons
import TopIcon from '../assets/roles/Top.png';
import JungleIcon from '../assets/roles/Jungle.png';
import MiddleIcon from '../assets/roles/Middle.png';
import BottomIcon from '../assets/roles/Bottom.png';
import SupportIcon from '../assets/roles/Support.png';
import FillIcon from '../assets/roles/Fill.png';

const roleIcons = {
  Top: TopIcon,
  Jungle: JungleIcon,
  Middle: MiddleIcon,
  Bottom: BottomIcon,
  Support: SupportIcon,
  Fill: FillIcon
};

const RoleIcon = ({ role, className, title }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!role) return null;

  return (
    <div className={`relative ${className}`}>
      <div 
        className={`w-full h-full bg-transparent rounded-full ${imageLoaded ? 'hidden' : 'block'}`}
      />
      <img 
        src={roleIcons[role]} 
        alt={`${role} Role`}
        className={`w-full h-full object-cover ${imageLoaded ? 'block' : 'hidden'}`}
        title={title}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

const QueueStatus = ({ 
  queueInfo, 
  getQueueTime, 
  onCancelQueue, 
  onResetRoles, 
  isDarkMode, 
  inQueue, 
  onJoinQueue,
  mapType,
  primaryRole,
  secondaryRole
}) => {
  const queueTime = getQueueTime();

  const handleJoinQueue = () => {
    if (mapType === "HOWLING_ABYSS") {
      onJoinQueue();
    } else {
      if (!primaryRole || (primaryRole !== 'Fill' && !secondaryRole)) {
        // You might want to handle this error in the parent component
        console.error('Please select both primary and secondary roles, or choose Fill as primary role.');
        return;
      }
      onJoinQueue(primaryRole, primaryRole === 'Fill' ? '' : secondaryRole);
    }
  };

  return (
    <div className={`${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 relative`}>
      {inQueue && (
        <X 
          className={`absolute top-2 right-2 cursor-pointer ${isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-400 hover:text-gray-600'}`}
          size={20}
          onClick={onResetRoles}
        />
      )}
      <h3 className="text-lg font-bold text-center">
        {inQueue ? 'FINDING MATCH' : ''}
      </h3>
      {inQueue && (
        <div className="flex justify-center items-center space-x-2 mb-3">
          <div className={`text-4xl font-bold ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`}>{formatTime(queueTime)}</div>
        </div>
      )}
      <div>
        <p className={`font-bold text-sm mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          Players in queue: {queueInfo.players}/10
        </p>
        <div className={`w-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2.5 mb-4 overflow-hidden`}>
          <div 
            className={`${isDarkMode ? 'bg-blue-500' : 'bg-blue-600'} h-2.5 rounded-full transition-all duration-500 ease-out`}
            style={{ width: `${(queueInfo.players / 10) * 100}%` }}
          ></div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        {mapType === "SUMMONERS_RIFT" ? (
          <div className="flex space-x-2 w-24">
            <div className="flex-1 flex justify-center">
              <RoleIcon 
                role={primaryRole}
                className="w-10 h-10"
                title={`Primary Role: ${primaryRole}`}
              />
            </div>
            <div className="flex-1 flex justify-center">
              <RoleIcon 
                role={secondaryRole}
                className="w-10 h-10"
                title={`Secondary Role: ${secondaryRole}`}
              />
            </div>
          </div>
        ) : (
          <div className="flex space-x-2 w-24">
            <div className="flex-1 flex justify-center">
              <RoleIcon 
                role={"Fill"}
                className="w-10 h-10"
                title={`Primary Role: ${primaryRole}`}
              />
            </div>
            <div className="flex-1 flex justify-center">
              <RoleIcon 
                role={secondaryRole}
                className="w-10 h-10 hidden"
                title={`Secondary Role: ${secondaryRole}`}
              />
            </div>
          </div>
        )
        }
        {inQueue ? (
          <button
            onClick={onCancelQueue}
            className={`${isDarkMode ? 'bg-red-600 hover:bg-red-700' : 'bg-red-500 hover:bg-red-600'} text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out`}
          >
            Leave Queue
          </button>
        ) : (
          <button
            onClick={handleJoinQueue}
            disabled={mapType === "SUMMONERS_RIFT" && (!primaryRole || (primaryRole !== 'Fill' && !secondaryRole))}
            className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out ${(mapType === "SUMMONERS_RIFT" && (!primaryRole || (primaryRole !== 'Fill' && !secondaryRole))) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Join Queue
          </button>
        )}
      </div>
    </div>
  );
};

export default QueueStatus;