import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const Pagination = ({ currentPage, totalPages, setCurrentPage, isDarkMode }) => {
  return (
    <div className="flex justify-center items-center mt-4 space-x-2">
      <button
        onClick={() => setCurrentPage(page => Math.max(1, page - 1))}
        disabled={currentPage === 1}
        className={`px-3 py-1 rounded transition-colors duration-150 ${
          isDarkMode
            ? 'bg-blue-600 text-white disabled:bg-gray-700 disabled:text-gray-500'
            : 'bg-blue-500 text-white disabled:bg-gray-300 disabled:text-gray-500'
        }`}
      >
        <ChevronLeft className="w-5 h-5" />
      </button>
      <span className={isDarkMode ? 'text-gray-300' : 'text-gray-700'}>
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => setCurrentPage(page => Math.min(totalPages, page + 1))}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 rounded transition-colors duration-150 ${
          isDarkMode
            ? 'bg-blue-600 text-white disabled:bg-gray-700 disabled:text-gray-500'
            : 'bg-blue-500 text-white disabled:bg-gray-300 disabled:text-gray-500'
        }`}
      >
        <ChevronRight className="w-5 h-5" />
      </button>
    </div>
  );
};

export default Pagination;