import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchPlayerStats, fetchPlayerGameName, fetchPlayerProfileIcon } from '../utils/api';
import { ArrowLeft, Users, Award, Activity, Trophy, Skull, Target, Clock, Coins, Crosshair, Eye, FileLineChart, Calendar } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend, LineChart, Line, CartesianGrid } from 'recharts';
import MatchHistoryPage from './MatchHistoryPage';

const PlayerProfile = ({ isDarkMode, isUNLMode }) => {
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [gameVersion, setGameVersion] = useState(null);
  const [gameName, setGameName] = useState('');
  const [tagLine, setTagLine] = useState('');
  const [profileIconId, setProfileIconId] = useState(null);
  const navigate = useNavigate();
  const { puuid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [playerStats, versions, playerGameName, profileIcon] = await Promise.all([
          fetchPlayerStats(isUNLMode, puuid),
          fetch('https://ddragon.leagueoflegends.com/api/versions.json').then(res => res.json()),
          fetchPlayerGameName(isUNLMode, puuid),
          fetchPlayerProfileIcon(isUNLMode, puuid)
        ]);
        
        if (!playerStats || Object.keys(playerStats).length === 0) {
          throw new Error('No data available for this player');
        }
        
        setPlayerData(playerStats);
        setGameVersion(versions[0]);
        setGameName(profileIcon.gameName);
        setTagLine(profileIcon.tagLine);
        setProfileIconId(profileIcon.profileIconId);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [puuid]);

  if (loading) {
    return (
      <div className={`flex justify-center items-center h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <div className={`text-2xl font-bold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'} animate-pulse`}>Loading player data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`container mx-auto ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
        <div className="p-4 md:p-8">
          <button
            onClick={() => navigate(-1)}
            className={`p-2 rounded-full ${
              isDarkMode 
                ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            } transition-colors duration-200 mb-4`}
            aria-label="Back to Leaderboard"
          >
            <ArrowLeft size={24} />
          </button>
          
          <div className="flex flex-col items-center justify-center h-[calc(100vh-300px)]">
            <h1 className={`text-4xl md:text-5xl font-bold ${isDarkMode ? 'text-gray-100' : 'text-gray-800'} mb-4 text-center`}>
              Player Not Found
            </h1>
            <p className={`text-xl md:text-2xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} text-center`}>
              There is no available information for this player.
            </p>
            <p className={`text-lg ${isDarkMode ? 'text-gray-500' : 'text-gray-500'} mt-4 text-center`}>
              The player may not exist in our database or there might be an issue with the data retrieval.
            </p>
          </div>
        </div>
      </div>
    );
  }

  const { overall_stats, role_stats, champion_stats } = playerData;

  const roleChartData = role_stats.map((role) => ({
    name: role.role.charAt(0).toUpperCase() + role.role.slice(1).toLowerCase(),
    games: role.gamesPlayed,
    winRate: (role.wins / role.gamesPlayed) * 100,
  }));

  const championChartData = champion_stats.map((champion) => ({
    name: champion.championName,
    games: champion.gamesPlayed,
    winRate: (champion.wins / champion.gamesPlayed) * 100,
    kda: (champion.kills + champion.assists) / (champion.deaths || 1),
    csPerMin: (champion.cs * 60 / champion.timePlayed).toFixed(1),
    damagePerMin: (champion.totalDamageDealtToChampions * 60 / champion.timePlayed).toFixed(0),
    timePlayed: champion.timePlayed
  })).sort((a, b) => b.games - a.games);

  const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];

  const formatTime = (seconds) => {
    if (typeof seconds !== 'number') return '0h 0m';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const getChampionIconUrl = (championName) => {
    if (!gameVersion) return '';
    return `https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${championName}.png`;
  };

  const StatCard = ({ icon: Icon, label, value, color }) => (
    <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4 flex flex-col items-center justify-center`}>
      <Icon size={24} className={`mb-2 ${color}`} />
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mb-1`}>{label}</p>
      <p className={`text-xl font-bold ${color}`}>{value}</p>
    </div>
  );

  const TabButton = ({ id, icon: Icon, label, activeTab, setActiveTab }) => (
    <button
      className={`flex items-center justify-center p-2 rounded-md transition-all duration-200 min-w-[120px] ${
        activeTab === id 
          ? 'bg-blue-600 text-white shadow-md' 
          : isDarkMode
            ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
      }`}
      onClick={() => setActiveTab(id)}
    >
      <Icon size={18} className="mr-2" />
      <span>{label}</span>
    </button>
  );

  const renderOverviewTab = () => (
    <div className="space-y-6 md:min-w-[1000px]">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-4">
        <StatCard icon={Trophy} label="Games Played" value={overall_stats.gamesPlayed} color="text-yellow-500" />
        <StatCard icon={Award} label="Wins" value={overall_stats.wins} color="text-green-500" />
        <StatCard icon={Skull} label="Losses" value={overall_stats.losses} color="text-red-500" />
        <StatCard
          icon={Target}
          label="Win Rate"
          value={`${((overall_stats.wins / overall_stats.gamesPlayed) * 100).toFixed(1)}%`}
          color="text-purple-500"
        />
        <StatCard
          icon={Crosshair}
          label="KDA"
          value={((overall_stats.kills + overall_stats.assists) / (overall_stats.deaths || 1)).toFixed(2)}
          color="text-blue-500"
        />
        <StatCard
          icon={Coins}
          label="CS/min"
          value={((overall_stats.cs * 60) / overall_stats.timePlayed).toFixed(1)}
          color="text-yellow-600"
        />
        <StatCard icon={Users} label="Solo Kills" value={overall_stats.soloKills} color="text-indigo-500" />
        <StatCard icon={Eye} label="Vision Score" value={overall_stats.visionScore} color="text-green-600" />
        <StatCard
          icon={Clock}
          label="Time Played"
          value={formatTime(overall_stats.timePlayed)}
          color="text-gray-600"
        />
        <StatCard
          icon={Activity}
          label="Damage/min"
          value={(overall_stats.totalDamageDealtToChampions * 60 / overall_stats.timePlayed).toFixed(0)}
          color="text-red-500"
        />
      </div>

      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4`}>
        <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'} flex items-center`}>
          <Award className="mr-2 text-yellow-500" /> Top Champions
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {championChartData.slice(0, 3).map((champion, index) => (
            <div key={champion.name} className={`flex items-center ${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-3`}>
              <img 
                src={getChampionIconUrl(champion.name)}
                alt={champion.name} 
                className="w-12 h-12 rounded-full mr-3 border-2 border-gray-300"
              />
              <div>
                <p className={`font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{champion.name}</p>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {champion.games} games, <span className="text-green-600 font-medium">{champion.winRate.toFixed(1)}% WR</span>
                </p>
                <p className={`text-xs ${isDarkMode ? 'text-gray-500' : 'text-gray-500'}`}>KDA: {champion.kda.toFixed(2)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4`}>
        <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'} flex items-center`}>
          <Users className="mr-2 text-blue-500" /> Role Performance
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={roleChartData}>
            <XAxis dataKey="name" stroke={isDarkMode ? "#A0AEC0" : "#4A5568"} />
            <YAxis yAxisId="left" orientation="left" stroke="#4F46E5" />
            <YAxis yAxisId="right" orientation="right" stroke="#10B981" />
            <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#2D3748' : '#FFFFFF' }} />
            <Legend />
            <Bar yAxisId="left" dataKey="games" name="Games Played" fill="#4F46E5" />
            <Bar yAxisId="right" dataKey="winRate" name="Win Rate (%)" fill="#10B981" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderMatchHistoryTab = () => (
    <div className="rounded-lg shadow-md md:min-w-[1000px]">
      <MatchHistoryPage puuid={puuid} isDarkMode={isDarkMode} isUNLMode={isUNLMode}/>
    </div>
  );

  const renderChampionsTab = () => (
    <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-md shadow-md p-4 md:min-w-[1000px]`}>
      <div className="hidden md:block">
        <table className="min-w-full rounded-md">
          <thead className={isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}>
            <tr>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Champion</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Games</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Win Rate</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>KDA</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>CS/min</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Damage/min</th>
              <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>Time Played</th>
            </tr>
          </thead>
          <tbody>
            {championChartData.map((champion) => (
              <tr key={champion.name} className={`border-b ${isDarkMode ? 'hover:bg-gray-700 border-gray-700' : 'hover:bg-gray-50 border-gray-200'}`}>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                  <div className="flex items-center">
                    <img 
                      src={getChampionIconUrl(champion.name)}
                      alt={champion.name}
                      className="w-8 h-8 rounded-full mr-2"
                    />
                    {champion.name}
                  </div>
                </td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{champion.games}</td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{champion.winRate.toFixed(1)}%</td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{champion.kda.toFixed(2)}</td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{champion.csPerMin}</td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>{champion.damagePerMin}</td>
                <td className={`py-2 px-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  {Math.floor(champion.timePlayed / 3600)}h {Math.floor((champion.timePlayed % 3600) / 60)}m
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="md:hidden space-y-4">
        {championChartData.map((champion) => (
          <div key={champion.name} className={`${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg p-4`}>
            <div className="flex items-center mb-2">
              <img 
                src={getChampionIconUrl(champion.name)}
                alt={champion.name}
                className="w-12 h-12 rounded-full mr-3"
              />
              <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>{champion.name}</h3>
            </div>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Games:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{champion.games}</span></p>
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Win Rate:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{champion.winRate.toFixed(1)}%</span></p>
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>KDA:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{champion.kda.toFixed(2)}</span></p>
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>CS/min:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{champion.csPerMin}</span></p>
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Damage/min:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{champion.damagePerMin}</span></p>
              <p><span className={`font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Time Played:</span> <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>{formatTime(champion.timePlayed)}</span></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderDamageTab = () => {
    const maxDamagePerMin = Math.max(...championChartData.map(champ => Number(champ.damagePerMin)));
    const yAxisMax = Math.ceil(maxDamagePerMin / 100) * 100;
  
    return (
      <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-6 md:min-w-[1000px]">
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4`}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={[
                  { name: 'To Champions', value: overall_stats.totalDamageDealtToChampions },
                  { name: 'To Structures', value: overall_stats.damageDealtToBuildings },
                  { name: 'Taken', value: overall_stats.totalDamageTaken }
                ]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {COLORS.map((color, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
              <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#2D3748' : '#FFFFFF', color: isDarkMode ? '#E2E8F0' : '#1A202C' }} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-4`}>
          <h2 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Damage Per Minute</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={championChartData}>
              <XAxis dataKey="name" stroke={isDarkMode ? "#A0AEC0" : "#4A5568"} />
              <YAxis domain={[0, yAxisMax]} stroke={isDarkMode ? "#A0AEC0" : "#4A5568"} />
              <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? "#4A5568" : "#E2E8F0"} />
              <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#2D3748' : '#FFFFFF', color: isDarkMode ? '#E2E8F0' : '#1A202C' }} />
              <Legend />
              <Line type="monotone" dataKey="damagePerMin" stroke="#FF6B6B" name="Damage/min" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const getProfileIconUrl = (iconId) => {
    if (!gameVersion || !iconId) return '';
    return `https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/profileicon/${iconId}.png`;
  };

  return (
    <div className={`container mx-auto min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
      <div className="p-4 md:p-8">
        <button
          onClick={() => navigate(-1)}
          className={`p-2 rounded-full ${
            isDarkMode 
              ? 'bg-gray-800 text-gray-300 hover:bg-gray-700' 
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          } transition-colors duration-200 mb-4`}
          aria-label="Back to Leaderboard"
        >
          <ArrowLeft size={24} />
        </button>
        
        <div className="flex flex-col items-center mb-4">
          <img 
            src={getProfileIconUrl(profileIconId)}
            alt="Profile Icon"
            className="w-24 h-24 md:w-32 md:h-32 rounded-full mb-4 border-4 border-blue-500 shadow-lg"
          />
          <h1 className={`text-4xl md:text-5xl font-bold ${isDarkMode ? 'text-gray-100' : 'text-gray-800'} mb-2`}>
            {gameName}
          </h1>
          <p className={`text-xl md:text-2xl ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
            #{tagLine}
          </p>
        </div>
        
        <div className="flex flex-wrap gap-2 mb-6 justify-center">
          <TabButton id="overview" icon={Users} label="Overview" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabButton id="matchHistory" icon={Calendar} label="Matches" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabButton id="champions" icon={Award} label="Champions" activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabButton id="damage" icon={Activity} label="Damage" activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        
        <div className="overflow-x-auto">
          {activeTab === 'overview' && renderOverviewTab()}
          {activeTab === 'matchHistory' && renderMatchHistoryTab()}
          {activeTab === 'champions' && renderChampionsTab()}
          {activeTab === 'damage' && renderDamageTab()}
        </div>
      </div>
    </div>
  );
};

export default PlayerProfile;