import { useState, useEffect } from 'react';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("useAuth effect running");
    const checkAuth = () => {
      const token = localStorage.getItem('riotToken');
      const storedUserData = localStorage.getItem('userData');
      
      console.log("Checking localStorage:", { token: !!token, userData: !!storedUserData });

      if (token && storedUserData) {
        try {
          const parsedUserData = JSON.parse(storedUserData);
          if (parsedUserData && parsedUserData.puuid) {
            console.log("Valid user data found, setting authenticated state");
            setIsAuthenticated(true);
            setUserData(parsedUserData);
            console.log("Authenticated with stored data. PUUID:", parsedUserData.puuid);
          } else {
            console.error("Stored user data is invalid");
            localStorage.removeItem('riotToken');
            localStorage.removeItem('userData');
          }
        } catch (error) {
          console.error("Error parsing stored user data:", error);
          localStorage.removeItem('riotToken');
          localStorage.removeItem('userData');
        }
      } else {
        console.log("Not authenticated. No stored token or user data found.");
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  return { isAuthenticated, setIsAuthenticated, userData, setUserData, isLoading };
};