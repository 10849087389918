import React, { useState, useEffect } from 'react';
import { Trophy, Swords, Users, Search, Shield, Hash } from 'lucide-react';
const config = require('../utils/config');

const ChampionStatisticsPage = ({ isDarkMode, isUNLMode }) => {
  const [championStats, setChampionStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'pick_ban_presence', direction: 'desc' });
  const [gameVersion, setGameVersion] = useState('');
  const [activeTab, setActiveTab] = useState('pick_ban_presence');
  const [minGames, setMinGames] = useState(0);
  const [maxGames, setMaxGames] = useState(0);
  const [activeMap, setActiveMap] = useState(isUNLMode ? 'SUMMONERS_RIFT' : 'SUMMONERS_RIFT');

  const tabs = [
    { id: 'SUMMONERS_RIFT', label: 'Summoner\'s Rift' },
    { id: 'HOWLING_ABYSS', label: 'Howling Abyss' },
  ];

  useEffect(() => {
    setActiveMap(isUNLMode ? 'SUMMONERS_RIFT' : activeMap);
  }, [isUNLMode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        const baseUrl = isUNLMode ? config.UNL_DATABASE_URL : config.DATABASE_URL;
        console.log('Base URL:', baseUrl);

        const [statsResponse, banStatsResponse, versionResponse] = await Promise.all([
          fetch(`${baseUrl}/api/champion-statistics`),
          fetch(`${baseUrl}/api/bans/statistics`),
          fetch('https://ddragon.leagueoflegends.com/api/versions.json')
        ]);
        
        console.log('Responses received:');
        console.log('Stats Response:', statsResponse);
        console.log('Ban Stats Response:', banStatsResponse);
        console.log('Version Response:', versionResponse);

        if (!statsResponse.ok || !banStatsResponse.ok || !versionResponse.ok) {
          throw new Error('Failed to fetch data');
        }
        
        const statsData = await statsResponse.json();
        const banStatsData = await banStatsResponse.json();
        const versionData = await versionResponse.json();
        
        console.log('Parsed data:');
        console.log('Stats Data:', statsData);
        console.log('Ban Stats Data:', banStatsData);
        console.log('Version Data:', versionData);

        if (!Array.isArray(statsData) || !Array.isArray(banStatsData) || !Array.isArray(versionData)) {
          throw new Error('Invalid data format received');
        }
  
        console.log('Combining stats...');
        const combinedStats = combineStats(statsData, banStatsData);
        console.log('Combined Stats:', combinedStats);

        setChampionStats(combinedStats);
        setGameVersion(versionData[0]);
        setMaxGames(Math.max(...combinedStats.map(champ => champ.gamesPlayed || 0)));
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load champion statistics. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchData();
  }, [isUNLMode]);

  const combineStats = (pickStats, banStats) => {
    console.log('Entering combineStats function');
    console.log('Pick Stats:', pickStats);
    console.log('Ban Stats:', banStats);
  
    const combinedStatsMap = new Map();
  
    console.log('Processing pick stats...');
    pickStats.forEach(pickStat => {
      console.log('Processing pick stat:', pickStat);
      const map = pickStat.map || 'SUMMONERS_RIFT'; // Set default map if undefined
      const key = `${pickStat.championName}-${map}`;
      combinedStatsMap.set(key, {
        ...pickStat,
        map, // Add map to the combined stats
        banCount: 0,
        banRate: 0,
        pick_ban_presence: pickStat.pick_rate || 0  // Initialize with pick rate
      });
      console.log('Added to map:', combinedStatsMap.get(key));
    });
  
    console.log('Processing ban stats...');
    banStats.forEach(banStat => {
      console.log('Processing ban stat:', banStat);
      const map = banStat.map || 'SUMMONERS_RIFT'; // Set default map if undefined
      const key = `${banStat.championName}-${map}`;
      if (combinedStatsMap.has(key)) {
        console.log('Updating existing entry for', key);
        const existingStats = combinedStatsMap.get(key);
        existingStats.banCount = banStat.banCount || 0;
        existingStats.banRate = banStat.banRate || 0;
        // Correct calculation of pick/ban presence
        existingStats.pick_ban_presence = 1 - ((1 - (existingStats.pick_rate / 100)) * (1 - (banStat.banRate / 100)));
        existingStats.pick_ban_presence *= 100; // Convert to percentage
        console.log('Updated entry:', existingStats);
      } else {
        console.log('Creating new entry for', key);
        combinedStatsMap.set(key, {
          championName: banStat.championName,
          map, // Add map to the combined stats
          gamesPlayed: 0,
          wins: 0,
          pick_rate: 0,
          win_rate: 0,
          avg_kills: 0,
          avg_deaths: 0,
          avg_assists: 0,
          avg_cs: 0,
          avg_damage_dealt: 0,
          avg_building_damage: 0,
          avg_damage_taken: 0,
          banCount: banStat.banCount || 0,
          banRate: banStat.banRate || 0,
          pick_ban_presence: banStat.banRate || 0  // If only ban data is available, use ban rate
        });
        console.log('New entry:', combinedStatsMap.get(key));
      }
    });
  
    const result = Array.from(combinedStatsMap.values());
    console.log('Combined stats result:', result);
    return result;
  };

  const getBackgroundColor = () => isDarkMode ? 'bg-gray-900' : 'bg-gray-100';
  const getTextColor = () => isDarkMode ? 'text-white' : 'text-gray-900';
  const getCardBackground = () => isDarkMode ? 'bg-gray-800' : 'bg-white';

  const sortedAndFilteredChampions = React.useMemo(() => {
    console.log('Filtering and sorting champions');
    console.log('Current activeMap:', activeMap);
    console.log('Current minGames:', minGames);
    console.log('Current searchTerm:', searchTerm);
  
    let filteredChampions = championStats.filter(champion => {
      const matchesSearch = champion.championName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesMap = isUNLMode || champion.map === activeMap;
      const matchesMinGames = champion.gamesPlayed >= minGames;
  
      console.log(`Champion ${champion.championName} - Map: ${champion.map}, Matches search: ${matchesSearch}, Matches map: ${matchesMap}, Matches min games: ${matchesMinGames}`);
  
      return matchesSearch && matchesMap && matchesMinGames;
    });
    
    console.log('Filtered champions:', filteredChampions);
  
    filteredChampions.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    
    console.log('Sorted champions:', filteredChampions);
    return filteredChampions;
  }, [championStats, sortConfig, searchTerm, minGames, isUNLMode, activeMap]);

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const tabConfig = {
    pick_ban_presence: { label: "Pick/Ban", icon: <Shield className="w-4 h-4" /> },
    pick_rate: { label: "Pick", icon: <Users className="w-4 h-4" /> },
    banRate: { label: "Ban", icon: <Shield className="w-4 h-4" /> },
    games_played: { label: "Games", icon: <Hash className="w-4 h-4" /> },
    win_rate: { label: "Win", icon: <Swords className="w-4 h-4" /> }
  };

  const ChampionListItem = ({ champion, rank }) => {
  
    const formatValue = (value, decimals = 1) => {
      if (value === undefined || value === null) {
        console.log('Value is undefined or null, returning N/A');
        return 'N/A';
      }
      return typeof value === 'number' ? value.toFixed(decimals) : value;
    };
  
    return (
      <div className={`${getCardBackground()} rounded-lg shadow-md p-3 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4`}>
        <span className="font-bold text-lg w-8 text-center">{rank}</span>
        <img
          src={`https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${champion.championName}.png`}
          alt={champion.championName}
          className="w-16 h-16 rounded-full"
        />
        <div className="flex-grow text-center md:text-left">
          <h3 className="font-bold text-lg md:text-xl">{champion.championName}</h3>
        </div>
        <div className="flex flex-wrap justify-center md:justify-end gap-2 md:gap-4 text-xs md:text-sm">
          <StatDisplay icon={<Shield className="w-4 h-4 md:w-5 md:h-5 mb-1 text-purple-500" />} label="Pick/Ban" value={`${formatValue(champion.pick_ban_presence)}%`} />
          <StatDisplay icon={<Users className="w-4 h-4 md:w-5 md:h-5 mb-1 text-blue-500" />} label="Pick" value={`${formatValue(champion.pick_rate)}%`} />
          <StatDisplay icon={<Shield className="w-4 h-4 md:w-5 md:h-5 mb-1 text-red-500" />} label="Ban" value={`${formatValue(champion.banRate)}%`} />
          <StatDisplay icon={<Hash className="w-4 h-4 md:w-5 md:h-5 mb-1 text-green-500" />} label="Games" value={formatValue(champion.gamesPlayed, 0)} />
          <StatDisplay icon={<Swords className="w-4 h-4 md:w-5 md:h-5 mb-1 text-yellow-500" />} label="Win" value={`${formatValue(champion.win_rate)}%`} />
          <StatDisplay icon={<Swords className="w-4 h-4 md:w-5 md:h-5 mb-1 text-orange-500" />} label="Kills" value={formatValue(champion.avg_kills)} />
          <StatDisplay icon={<Swords className="w-4 h-4 md:w-5 md:h-5 mb-1 text-red-500" />} label="Deaths" value={formatValue(champion.avg_deaths)} />
          <StatDisplay icon={<Users className="w-4 h-4 md:w-5 md:h-5 mb-1 text-blue-500" />} label="Assists" value={formatValue(champion.avg_assists)} />
          <StatDisplay icon={<Hash className="w-4 h-4 md:w-5 md:h-5 mb-1 text-green-500" />} label="CS" value={formatValue(champion.avg_cs)} />
          <StatDisplay icon={<Swords className="w-4 h-4 md:w-5 md:h-5 mb-1 text-purple-500" />} label="DMG" value={formatValue(champion.avg_damage_dealt, 0)} />
        </div>
      </div>
    );
  };
  
  const StatDisplay = ({ icon, label, value }) => {
    return (
      <div className="flex flex-col items-center justify-between min-w-[60px] md:min-w-[70px]">
        {icon}
        <span className="font-semibold text-center whitespace-nowrap">{label}</span>
        <span className="text-center whitespace-nowrap">{value}</span>
      </div>
    );
  };
  
  const TabButton = ({ label, tabKey, icon }) => (
    <button
      onClick={() => {
        setActiveTab(tabKey);
        handleSort(tabKey);
      }}
      className={`px-2 md:px-4 py-2 rounded-full font-medium transition-colors duration-200 ease-in-out flex items-center space-x-1 md:space-x-2 text-xs md:text-sm
        ${activeTab === tabKey
          ? (isDarkMode ? 'bg-blue-600 text-white' : 'bg-blue-500 text-white')
          : (isDarkMode ? 'bg-gray-700 text-gray-200 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300')
        }`}
    >
      {icon}
      <span>{label}</span>
    </button>
  );

  if (loading) return <div className={`text-center mt-8 ${getTextColor()}`}>Loading champion statistics...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className={`container mx-auto px-2 md:px-4 py-4 ${getTextColor()}`}>
      {!isUNLMode && (
        <div className="container mx-auto px-4 py-1 mb-4">
          <div className={`flex space-x-1 rounded-xl p-1 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}`}>
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveMap(tab.id)}
                className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                  ${activeMap === tab.id 
                    ? (isDarkMode ? 'bg-gray-600 text-white' : 'bg-white text-blue-700 shadow') 
                    : (isDarkMode ? 'text-gray-400 hover:bg-gray-600 hover:text-white' : 'text-gray-700 hover:bg-white/[0.12] hover:text-blue-700')
                  }
                  transition-all duration-200 ease-in-out
                `}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Search champions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full p-3 pl-12 rounded-full shadow-md ${
              isDarkMode 
                  ? 'bg-gray-800 text-white placeholder-gray-400' 
                  : 'bg-white text-gray-900 placeholder-gray-500'
            } focus:outline-none transition duration-300`}
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>
      


      <div className="flex flex-wrap justify-center gap-2 mb-8">
        {Object.entries(tabConfig).map(([key, { label, icon }]) => (
          <TabButton key={key} label={label} tabKey={key} icon={icon} />
        ))}
      </div>

      {activeTab === 'win_rate' && (
        <div className="mb-4 flex items-center justify-center flex-wrap">
          <label htmlFor="minGames" className="mr-2 text-sm md:text-base">Minimum games played:</label>
          <input
            type="number"
            id="minGames"
            value={minGames}
            onChange={(e) => {
              const value = Math.min(Math.max(parseInt(e.target.value) || 0, 0), maxGames);
              setMinGames(value);
            }}
            min="0"
            max={maxGames}
            className={`w-20 p-2 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-900'} text-sm md:text-base`}
          />
          <span className="ml-2 text-sm md:text-base">/ {maxGames}</span>
        </div>
      )}
      
      <div className="space-y-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4">All Champions</h2>
        {
        sortedAndFilteredChampions.length > 0 ? (
          sortedAndFilteredChampions.map((champion, index) => (
            <ChampionListItem key={`${champion.championName}-${champion.map}`} champion={champion} rank={index + 1} />
          ))
        ) : (
          <p>No champions found matching the current criteria.</p>
        )}
      </div>
    </div>
  );
};

export default ChampionStatisticsPage;