import React, { useEffect, useState, useCallback, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import io from 'socket.io-client';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LeaderboardPage from './components/LeaderboardPage';
import MatchHistoryPage from './components/MatchHistoryPage';
import HomePage from './components/HomeQueuePage';
import PlayerProfile from './components/ProfilePage';
import SignInPage from './components/SignInPage';
import AdminTestingPage from './components/AdminTestingPage';
import MatchFoundModal from './components/MatchFoundModal';
import ChampionStatisticsPage from './components/ChampionStatisticsPage';
import LobbyPage from './components/LobbyPage';
import StandingsPage from './components/StandingsPage';
import SchedulePage from './components/SchedulePage';
import { useAuth } from './hooks/useAuth';
import { useQueue } from './hooks/useQueue';
import sound from './assets/qpop.mp3';
import UNLAdminTournamentCodeGenerator from './components/UNLAdminTournamentCodeGenerator';
const config = require('./utils/config');

const socket = io(config.WEBSOCKETS_URL, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

const AppContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, userData, setIsAuthenticated, setUserData } = useAuth();
  const queueHook = useQueue(socket, userData);
  const [isConnected, setIsConnected] = useState(socket.connected);

  const {
    inQueue,
    queueInfo,
    matchFound,
    matchedPlayers,
    handleQueueJoin,
    handleQueueLeave,
    handleAcceptMatch,
    handleDeclineMatch,
    getQueueTime
  } = queueHook;

  const [showMatchFoundModal, setShowMatchFoundModal] = useState(false);
  const [acceptedPlayers, setAcceptedPlayers] = useState(0);
  const [countdownProgress, setCountdownProgress] = useState(100);
  const [userAccepted, setUserAccepted] = useState(false);
  const [tournamentCode, setTournamentCode] = useState(null);
  const [lobbyPlayers, setLobbyPlayers] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode !== null ? JSON.parse(savedMode) : true;
  });
  const [isUNLMode, setIsUNLMode] = useState(() => {
    const savedMode = localStorage.getItem('isUNLMode');
    return savedMode !== null ? JSON.parse(savedMode) : false;
  });
  const [notificationPermission, setNotificationPermission] = useState('default');

  const audioContext = useRef(null);
  const audioBuffer = useRef(null);
  const audioSource = useRef(null);

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUserData(null);
    localStorage.removeItem('riotToken');
    localStorage.removeItem('userData');
  };

  const toggleTheme = useCallback(() => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', JSON.stringify(newMode));
      return newMode;
    });
  }, []);

  const toggleUNLMode = useCallback(() => {
    setIsUNLMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('isUNLMode', JSON.stringify(newMode));
      // Refresh the page
      window.location.reload();
      return newMode;
    });
  }, []);

  useEffect(() => {
    const initAudio = async () => {
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(sound);
      const arrayBuffer = await response.arrayBuffer();
      audioBuffer.current = await audioContext.current.decodeAudioData(arrayBuffer);
    };
    initAudio();
  }, []);

  const requestNotificationPermission = useCallback(async () => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
    }
  }, []);

  const showNotification = useCallback(() => {
    if (notificationPermission === 'granted') {
      new Notification('Match Found!', {
        body: 'A match has been found!',
        icon: './assets/theQ.png'
      });
    }
  }, [notificationPermission]);

  const playNotificationSound = useCallback(() => {
    if (audioContext.current && audioBuffer.current && !audioSource.current) {
      audioSource.current = audioContext.current.createBufferSource();
      audioSource.current.buffer = audioBuffer.current;
      audioSource.current.connect(audioContext.current.destination);
      audioSource.current.start();
    }
  }, []);

  const stopNotificationSound = useCallback(() => {
    if (audioSource.current) {
      audioSource.current.stop();
      audioSource.current = null;
    }
  }, []);

  const focusWindow = useCallback(() => {
    window.focus();
  }, []);

  const redirectToLobby = useCallback((code) => {
    console.log('Redirecting to lobby:', code);
    if (location.pathname !== '/admin') {
      setShowMatchFoundModal(false);
      navigate(`/lobby/${code}`);
    } else {
      console.log('Not redirecting to lobby: User is on admin page');
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    requestNotificationPermission();
  }, [requestNotificationPermission]);

  useEffect(() => {
    if (matchFound && isAuthenticated && (location.pathname !== '/admin' && !location.pathname.startsWith('/lobby'))) {
      setShowMatchFoundModal(true);
      setAcceptedPlayers(0);
      setCountdownProgress(100);
      setUserAccepted(false);

      showNotification();
      playNotificationSound();
      focusWindow();
    } else {
      setShowMatchFoundModal(false);
    }
  }, [matchFound, location.pathname, showNotification, playNotificationSound, stopNotificationSound, focusWindow, isAuthenticated]);

  useEffect(() => {
    let countdownInterval;
    if (matchFound && isAuthenticated && location.pathname !== '/admin' && !location.pathname.startsWith('/lobby')) {
      countdownInterval = setInterval(() => {
        setCountdownProgress((prev) => {
          if (prev <= 0) {
            clearInterval(countdownInterval);
            handleTimeRunOut();
            return 0;
          }
          return prev - (100 / 150); // Decrease by 100/150 every 100ms for a 15-second countdown
        });
      }, 100);
    }

    return () => {
      if (countdownInterval) clearInterval(countdownInterval);
    };
  }, [matchFound, location.pathname, isAuthenticated]);

  const adminPuuids = [
    "83c62h2tNPGONAbqvdqsbvjF14kuUJXYC8cfmHPkHY_X49Bt5_qmplN5Rcuz1Ui2NpsL0bEBpUfrbw",
    "onPZDSck53JFaZgqPKYN-66cbYfoG97B69sTpsJDq3upaDj5aubiG56jKxj30zkpi1gohfuzG3pQBQ"
  ];

  useEffect(() => {
    const onConnect = () => {
      console.log('Connected to server');
      setIsConnected(true);
    };

    const onDisconnect = (reason) => {
      console.log('Disconnected from server:', reason);
      setIsConnected(false);
    };

    const onReconnect = (attemptNumber) => {
      console.log('Reconnected to server after', attemptNumber, 'attempts');
      if (userData && userData.puuid) {
        socket.emit('register', userData.puuid);
        const savedQueueState = JSON.parse(localStorage.getItem('queueState'));
        if (savedQueueState && savedQueueState.inQueue) {
          console.log('Rejoining queue with saved state:', savedQueueState);
          socket.emit('rejoinQueue', savedQueueState);
        }
      }
    };

    const onMatchStarted = (data) => {
      console.log('Match started:', data);
      stopNotificationSound();
      if (location.pathname !== '/admin') {
        setShowMatchFoundModal(false);
        setTournamentCode(data.tournamentCode);
        setLobbyPlayers(data.players);
        redirectToLobby(data.tournamentCode);
      } else {
        console.log('Match started, but not redirecting: User is on admin page');
      }
    };

    const onPlayerAccepted = (count) => {
      console.log('Player accepted, count:', count);
      setAcceptedPlayers(count);
    };

    const onMatchCancelled = (userStayedInQueue) => {
      console.log('Match cancelled, user stayed in queue:', userStayedInQueue);
      setShowMatchFoundModal(false);
      setTournamentCode(null);
      setLobbyPlayers([]);
      if (!userStayedInQueue && location.pathname !== '/admin') {
        handleQueueLeave();
      }
      setUserAccepted(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('reconnect', onReconnect);
    socket.on('matchStarted', onMatchStarted);
    socket.on('playerAccepted', onPlayerAccepted);
    socket.on('matchCancelled', onMatchCancelled);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('reconnect', onReconnect);
      socket.off('matchStarted', onMatchStarted);
      socket.off('playerAccepted', onPlayerAccepted);
      socket.off('matchCancelled', onMatchCancelled);
    };
  }, [socket, userData, handleQueueLeave, redirectToLobby, location.pathname, stopNotificationSound]);

  const handleTimeRunOut = () => {
    if (acceptedPlayers < 10 && location.pathname !== '/admin') {
      socket.emit('cancelMatch', userData.puuid);
    }
  };

  const handleAccept = () => {
    setUserAccepted(true);
    handleAcceptMatch(userData.puuid);
  };

  const handleCloseLobby = () => {
    setTournamentCode(null);
    setLobbyPlayers([]);
    handleQueueLeave();
  };

  // Apply UNL theme to the entire app
  useEffect(() => {
      document.body.style.backgroundColor = isDarkMode ? '#1f2937' : 'white';
      document.body.style.color = isDarkMode ? 'white' : 'black';
  }, [isUNLMode, isDarkMode]);

  useEffect(() => {
    const checkUNLHash = () => {
      if (location.hash === '#unl') {
        setIsUNLMode(true);
        localStorage.setItem('isUNLMode', JSON.stringify(true));
        // Remove the #unl hash from the URL without navigating
        window.history.replaceState(null, '', location.pathname + location.search);
      }
    };

    checkUNLHash();

    // Listen for hash changes
    const handleHashChange = () => {
      checkUNLHash();
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
      <NavBar
        userData={userData}
        inQueue={inQueue}
        queueInfo={queueInfo}
        handleLogout={handleLogout}
        isDarkMode={isDarkMode}
        toggleTheme={toggleTheme}
        isAuthenticated={isAuthenticated}
        isConnected={isConnected}
        isUNLMode={isUNLMode}
        toggleUNLMode={toggleUNLMode}
      />

      <main className="flex-grow flex flex-col">
        <Routes>
          <Route
            path="/"
            element={
              <div className="flex-grow flex items-center justify-center">
                <HomePage
                  socket={socket}
                  userData={userData}
                  inQueue={inQueue}
                  queueInfo={queueInfo}
                  handleQueueJoin={handleQueueJoin}
                  handleQueueLeave={handleQueueLeave}
                  getQueueTime={getQueueTime}
                  isDarkMode={isDarkMode}
                  isAuthenticated={isAuthenticated}
                  setIsAuthenticated={setIsAuthenticated}
                  setUserData={setUserData}
                  isUNLMode={isUNLMode}
                />
              </div>
            }
          />
          <Route path="/leaderboard" element={<div className="flex-grow"><LeaderboardPage isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route path="/match-history" element={<div className="flex-grow"><MatchHistoryPage isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route path="/standings" element={<div className="flex-grow"><StandingsPage isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route path="/champion-statistics" element={<div className="flex-grow"><ChampionStatisticsPage isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route path="/profile/:puuid" element={<div className="flex-grow"><PlayerProfile isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route path="/schedule" element={<div className="flex-grow"><SchedulePage isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>} />
          <Route
            path="/admin"
            element={
              isAuthenticated && userData ? (
                adminPuuids.includes(userData.puuid) ? (
                  <div className="flex-grow"><AdminTestingPage socket={socket} isDarkMode={isDarkMode} queueInfo={queueInfo} isUNLMode={isUNLMode} /></div>
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <div>Loading...</div>
              )
            }
          />
          <Route
            path="/unladmin"
            element={
              isAuthenticated && userData ? (
                adminPuuids.includes(userData.puuid) ? (
                  <div className="flex-grow"><UNLAdminTournamentCodeGenerator isDarkMode={isDarkMode} isUNLMode={isUNLMode} /></div>
                ) : (
                  <Navigate to="/" replace />
                )
              ) : (
                <div>Loading...</div>
              )
            }
          />
          <Route
            path="/lobby/:tournament_code"
            element={
              isAuthenticated ? (
                <div className="flex-grow">
                  <LobbyPage
                    socket={socket}
                    userData={userData}
                    matchedPlayers={lobbyPlayers}
                    onClose={handleCloseLobby}
                    isDarkMode={isDarkMode}
                    isUNLMode={isUNLMode}
                  />
                </div>
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route path="/oauth-callback" element={<SignInPage setIsAuthenticated={setIsAuthenticated} setUserData={setUserData} isDarkMode={isDarkMode} isUNLMode={isUNLMode} />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </main>
      <div className="mt-4">
        <Footer isDarkMode={isDarkMode} isUNLMode={isUNLMode} />
      </div>

      {showMatchFoundModal && isAuthenticated && location.pathname !== '/admin' && !location.pathname.startsWith('/lobby') && (
        <MatchFoundModal
          acceptedPlayers={acceptedPlayers}
          countdownProgress={countdownProgress}
          onAccept={handleAccept}
          isDarkMode={isDarkMode}
          isUNLMode={isUNLMode}
        />
      )}
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;