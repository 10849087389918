import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import { getChampionIconUrl } from '../utils/utils';

const roleOrder = ['TOP', 'JUNGLE', 'MIDDLE', 'BOTTOM', 'UTILITY'];

const DamageChartSkeleton = ({ isDarkMode }) => (
  <div className={`mt-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow p-4 animate-pulse`}>
    <div className={`h-6 w-48 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded mb-4`}></div>
    <div className="h-[400px] md:h-[500px] lg:h-[600px]">
      <div className="flex flex-col space-y-2">
        {[...Array(10)].map((_, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div className={`w-8 h-8 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded-full flex-shrink-0`}></div>
            <div className={`h-6 ${index < 5 ? 'bg-blue-300' : 'bg-red-300'} rounded w-full`} style={{width: `${Math.random() * 80 + 20}%`}}></div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const DamageChart = ({ matchDetails, gameVersion, isDarkMode }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  if (!matchDetails || !matchDetails.participants) {
    return <DamageChartSkeleton isDarkMode={isDarkMode} />;
  }

  const sortParticipants = (participants) => {
    return participants.sort((a, b) => 
      roleOrder.indexOf(a.individualPosition) - roleOrder.indexOf(b.individualPosition)
    );
  };

  const blueTeam = sortParticipants(matchDetails.participants.filter(p => p.teamId === 100));
  const redTeam = sortParticipants(matchDetails.participants.filter(p => p.teamId === 200));

  const chartData = [...blueTeam, ...redTeam].map(player => ({
    name: player.championName,
    damage: player.totalDamageDealtToChampions,
    color: player.teamId === 100 ? '#1E90FF' : '#ff3333',
    hoverColor: player.teamId === 100 ? '#0000CD' : '#8B0000', // Darker colors for hover
    playerName: player.gameName,
    role: player.individualPosition
  }));

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={-32} y={-16} width={32} height={32}>
          <div
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isDarkMode ? '#4a5568' : '#f0f0f0',
            }}
          >
            <img
              src={getChampionIconUrl(payload.value, gameVersion)}
              alt={payload.value}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border p-2 shadow-md rounded`}>
          <p className={`font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{payload[0].payload.playerName}</p>
          <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>{payload[0].payload.role}</p>
          <p className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>{payload[0].value.toLocaleString()} damage</p>
        </div>
      );
    }
    return null;
  };

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div className={`mt-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow p-4`}>
      <h3 className={`text-lg font-semibold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Total Damage to Champions</h3>
      <div className="h-[400px] md:h-[500px] lg:h-[600px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={chartData} 
            layout="vertical" 
            margin={{ top: 2, right: 30, left: 40, bottom: 2 }}
            barGap={0}
            barCategoryGap={4}
            onMouseMove={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <XAxis 
              type="number" 
              tick={{ fill: isDarkMode ? '#e2e8f0' : '#4a5568' }}
            />
            <YAxis 
              dataKey="name" 
              type="category" 
              width={60} 
              tick={<CustomYAxisTick />}
              interval={0}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="damage" barSize={42}>
              {chartData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={index === activeIndex ? entry.hoverColor : entry.color}
                  style={{ transition: 'fill 0.3s ease' }}
                />
              ))}
            </Bar>
            <ReferenceLine x={0} stroke={isDarkMode ? '#718096' : '#000'} />
            <ReferenceLine x={0} y={4.5} stroke={isDarkMode ? '#718096' : '#000'} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DamageChart;