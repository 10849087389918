import React from 'react';
import { getChampionIconUrl } from '../utils/utils';

const roleOrder = ['TOP', 'JUNGLE', 'MIDDLE', 'BOTTOM', 'UTILITY'];

const TeamTableSkeleton = ({ teamColor, isDarkMode }) => {
  const getBgColor = () => {
    if (isDarkMode) {
      return teamColor === 'blue' ? 'bg-blue-900' : 'bg-red-900';
    }
    return teamColor === 'blue' ? 'bg-blue-50' : 'bg-red-50';
  };

  return (
    <div className={`flex flex-col rounded-xl shadow border-2 ${getBgColor()} ${isDarkMode ? 'border-gray-700' : 'border-gray-300'}`}>
      <table className="w-full rounded-lg">
        <thead>
          <tr>
            <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Player</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>K/D/A</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>CS</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>Vision</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, index) => (
            <tr key={index} className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <td className="py-2 px-4">
                <div className="flex items-center">
                  <div className={`w-8 h-8 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} mr-2`}></div>
                  <div>
                    <div className={`w-24 h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded`}></div>
                    <div className={`w-16 h-3 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded mt-1`}></div>
                  </div>
                </div>
              </td>
              <td className="py-2 px-4 text-center">
                <div className={`w-16 h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded mx-auto`}></div>
              </td>
              <td className="py-2 px-4 text-center">
                <div className={`w-12 h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded mx-auto`}></div>
              </td>
              <td className="py-2 px-4 text-center">
                <div className={`w-12 h-4 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-300'} rounded mx-auto`}></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TeamTable = ({ teamParticipants, isWinner, handlePlayerClick, gameVersion, teamColor, isDarkMode }) => {
  if (!teamParticipants || teamParticipants.length === 0) {
    return <TeamTableSkeleton teamColor={teamColor} isDarkMode={isDarkMode} />;
  }

  const sortedParticipants = teamParticipants.sort((a, b) => 
    roleOrder.indexOf(a.individualPosition) - roleOrder.indexOf(b.individualPosition)
  );

  const getBgColor = () => {
    if (isDarkMode) {
      return teamColor === 'blue' ? 'bg-blue-900' : 'bg-red-900';
    }
    return teamColor === 'blue' ? 'bg-blue-50' : 'bg-red-50';
  };

  const getBorderColor = () => {
    if (isDarkMode) {
      return isWinner ? 'border-green-500' : (teamColor === 'blue' ? 'border-blue-700' : 'border-red-700');
    }
    return isWinner ? 'border-green-500' : (teamColor === 'blue' ? 'border-blue-200' : 'border-red-200');
  };

  return (
    <div className={`flex flex-col rounded-xl shadow border-2 ${getBgColor()} ${getBorderColor()}`}>
      <table className="w-full rounded-lg">
        <thead>
          <tr>
            <th className={`py-2 px-4 text-left ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Player</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>K/D/A</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>CS</th>
            <th className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Vision</th>
          </tr>
        </thead>
        <tbody>
          {sortedParticipants.map((player) => (
            <tr key={player.puuid} className={`border-t ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
              <td className="py-2 px-4">
                <div className="flex items-center">
                  <img
                    src={getChampionIconUrl(player.championName, gameVersion)}
                    alt={player.championName}
                    className="w-8 h-8 mr-2 rounded-full"
                  />
                  <div>
                    <button
                      onClick={() => handlePlayerClick(player.puuid)}
                      className={`${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-800'} hover:underline`}
                    >
                      {player.gameName}
                    </button>
                    <div className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{player.individualPosition}</div>
                  </div>
                </div>
              </td>
              <td className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
                {player.kills}/{player.deaths}/{player.assists}
              </td>
              <td className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>{player.cs}</td>
              <td className={`py-2 px-4 text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>{player.visionScore}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamTable;