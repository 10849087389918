import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import TopIcon from '../assets/roles/Top.png';
import JungleIcon from '../assets/roles/Jungle.png';
import MiddleIcon from '../assets/roles/Middle.png';
import BottomIcon from '../assets/roles/Bottom.png';
import SupportIcon from '../assets/roles/Support.png';
import FillIcon from '../assets/roles/Fill.png';

const allRoles = [
    { name: 'Top', icon: TopIcon },
    { name: 'Jungle', icon: JungleIcon },
    { name: 'Middle', icon: MiddleIcon },
    { name: 'Bottom', icon: BottomIcon },
    { name: 'Support', icon: SupportIcon },
    { name: 'Fill', icon: FillIcon }
];

const RoleSelection = ({ primaryRole, secondaryRole, onRoleChange, isDarkMode }) => {
    const [showSecondary, setShowSecondary] = useState(false);
    const [guideText, setGuideText] = useState("Select your primary role");
    const [roles, setRoles] = useState(allRoles);

    useEffect(() => {
        if (primaryRole === 'Fill') {
            setShowSecondary(false);
            setGuideText("Ready to go!");
            setRoles([allRoles.find(role => role.name === 'Fill')]);
        } else if (primaryRole && !secondaryRole) {
            setShowSecondary(true);
            setGuideText("Select your secondary role");
            setRoles([allRoles.find(role => role.name === primaryRole)]);
        } else if (primaryRole && secondaryRole) {
            setShowSecondary(false);
            setGuideText("Ready to go!");
            setRoles([
                allRoles.find(role => role.name === primaryRole),
                allRoles.find(role => role.name === secondaryRole)
            ]);
        } else {
            setShowSecondary(false);
            setGuideText("Select your primary role");
            setRoles(allRoles);
        }
    }, [primaryRole, secondaryRole]);

    const handleReset = () => {
        onRoleChange(null, true);
        onRoleChange(null, false);
        setRoles(allRoles);
    };

    const textVariants = {
        initial: { opacity: 0, y: -10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
        transition: { duration: 0.2 }
    };

    const containerVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.2 }
    };

    const secondaryVariants = {
        initial: { opacity: 0, height: 0 },
        animate: { opacity: 1, height: 'auto' },
        exit: { opacity: 0, height: 0 },
        transition: { duration: 0.2 }
    };

    return (
        <div className={`relative ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}>
            <AnimatePresence mode="wait">
                <motion.div
                    key={guideText}
                    variants={textVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="text-center text-xl font-semibold mb-4"
                >
                    {guideText}
                </motion.div>
            </AnimatePresence>
            <div className="mb-4">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={primaryRole && secondaryRole ? 'both' : primaryRole ? 'primary' : 'all'}
                        variants={containerVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className={`flex flex-wrap justify-center gap-2 sm:gap-4 ${primaryRole && secondaryRole ? 'sm:space-x-8' : ''}`}
                    >
                        {roles.map((role) => (
                            <motion.button
                                key={role.name}
                                onClick={() => !secondaryRole && onRoleChange(role.name, secondaryRole ? false : true)}
                                className={`p-1 sm:p-2 rounded-full ${
                                    (primaryRole === role.name || secondaryRole === role.name)
                                        ? 'border-4 border-blue-600'
                                        : 'border-4 border-transparent'
                                } ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
                                whileTap={{ scale: 0.95 }}
                                transition={{ duration: 0.1 }}
                            >
                                <img src={role.icon} alt={role.name} className="w-8 h-8 sm:w-10 sm:h-10 object-contain" />
                            </motion.button>
                        ))}
                    </motion.div>
                </AnimatePresence>
            </div>
            <AnimatePresence>
                {showSecondary && (
                    <motion.div
                        variants={secondaryVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="mb-4 overflow-hidden"
                    >
                        <div className="flex flex-wrap justify-center gap-2 sm:gap-4">
                            {allRoles.filter(role => role.name !== 'Fill').map((role) => (
                                <motion.button
                                    key={role.name}
                                    onClick={() => role.name !== primaryRole && onRoleChange(role.name, false)}
                                    className={`p-1 sm:p-2 rounded-full ${
                                        secondaryRole === role.name
                                            ? 'border-4 border-blue-600'
                                            : 'border-4 border-transparent'
                                    } ${
                                        role.name === primaryRole
                                            ? 'opacity-50 cursor-not-allowed'
                                            : ''
                                    } ${isDarkMode ? 'bg-gray-700' : 'bg-gray-100'}`}
                                    whileTap={role.name !== primaryRole ? { scale: 0.95 } : {}}
                                    transition={{ duration: 0.1 }}
                                >
                                    <img
                                        src={role.icon}
                                        alt={role.name}
                                        className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                                        onError={(e) => {
                                            console.error(`Failed to load image: ${role.icon}`);
                                        }}
                                    />
                                </motion.button>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {(primaryRole || secondaryRole) && (
                <button
                    onClick={handleReset}
                    className={`absolute top-0 right-0 p-2 ${
                        isDarkMode 
                            ? 'text-gray-300 hover:text-white' 
                            : 'text-gray-500 hover:text-gray-700'
                    } transition-colors duration-200`}
                    aria-label="Reset selection"
                >
                    <X size={24} />
                </button>
            )}
        </div>
    );
};

export default RoleSelection;