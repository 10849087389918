import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchMatches, fetchMatchDetails } from '../utils/api';

export const useMatchHistory = (puuid, isUNLMode) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedMatch, setExpandedMatch] = useState(null);
  const [gameVersion, setGameVersion] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [matchesPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [matchesData, versionsResponse] = await Promise.all([
          fetchMatches(isUNLMode, puuid),
          fetch('https://ddragon.leagueoflegends.com/api/versions.json')
        ]);
        const versions = await versionsResponse.json();
        
        // No need to filter matches here as the API now returns only relevant matches
        const sortedMatches = matchesData.sort((a, b) => b.gameCreation - a.gameCreation);
        setMatches(sortedMatches);
        setGameVersion(versions[0]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [puuid]);

  const handleMatchExpand = async (match) => {
    if (expandedMatch && expandedMatch.matchId === match.matchId) {
      setExpandedMatch(null);
    } else {
      try {
        const details = await fetchMatchDetails(isUNLMode, match.matchId);
        setExpandedMatch(details);
      } catch (error) {
        console.error('Error fetching match details:', error);
      }
    }
  };

  const handlePlayerClick = (playerId) => {
    navigate(`/profile/${playerId}`);
  };

  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = matches.slice(indexOfFirstMatch, indexOfLastMatch);
  const totalPages = Math.ceil(matches.length / matchesPerPage);

  return {
    matches,
    loading,
    expandedMatch,
    gameVersion,
    currentPage,
    matchesPerPage,
    totalPages,
    currentMatches,
    handleMatchExpand,
    handlePlayerClick,
    setCurrentPage,
  };
};